export const MERCHANT_LOGIN_SUCCESS = "MERCHANT_LOGIN_SUCCESS";
export const MERCHANT_LOGIN_FAILD   = "MERCHANT_LOGIN_FAILD";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const RESEND_OTP_FAILD = "RESEND_OTP_FAILD";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";

export const SET_MESSAGE   = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const LOADING_SUCCESS  = "LOADING_SUCCESS";
export const LOADING_FAILD    = "LOADING_FAILD";
export const VERIFY_OTP_FAILD   = "VERIFY_OTP_FAILD";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILD   = "UPDATE_COMPANY_FAILD";
export const PROFILE_UPDATE_FAILD   = "PROFILE_UPDATE_FAILD";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const CHANGE_PASSWORD_SUCCESS   = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILD     = "CHANGE_PASSWORD_FAILD";
export const UPDATE_PASSWORD_SUCCESS   = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILD     = "UPDATE_PASSWORD_FAILD";
export const UPDATE_PROFILE_SUCCESS   = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_USER_SFID         = "UPDATE_USER_SFID";
export const UPDATE_PROFILE_FAILD     = "UPDATE_PROFILE_FAILD";
export const GET_LEADS_SUCCESS   = "GET_LEADS_SUCCESS";
export const GET_LEADS_FAILD     = "GET_LEADS_FAILD";
export const GET_SETTLEMENT_SUCCESS     = "GET_SETTLEMENT_SUCCESS";
export const GET_SETTLEMENT_FAILD     = "GET_SETTLEMENT_FAILD";
export const GET_SETTLEMENT_SUMMARY_FAILD = "GET_SETTLEMENT_SUMMARY_FAILD";
export const GET_SETTLEMENT_SUMMARY_SUCCESS = "GET_SETTLEMENT_SUMMARY_SUCCESS";

export const GET_BANK_SUCCESS = "GET_BANK_SUCCESS";
export const GET_BANK_FAILD   = "GET_BANK_FAILD";
export const UPDATE_BANK_SUCCESS = "UPDATE_BANK_SUCCESS";
export const UPDATE_BANK_FAILD   = "UPDATE_BANK_FAILD";
export const CLEAR_USER_MESSAGE   = "CLEAR_USER_MESSAGE";
export const CLEAR_AUTH_MESSAGE   = "CLEAR_AUTH_MESSAGE";
export const SALESFORCE_LOGIN_SUCCESS  = "SALESFORCE_LOGIN_SUCCESS";
export const SALESFORCE_LOGIN_FAILD    = "SALESFORCE_LOGIN_FAILD";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILD   = "GET_USERS_FAILD";
export const MERCHANT_PRODUCTS_SUCCESS = "MERCHANT_PRODUCTS_SUCCESS";
export const MERCHANT_PRODUCTS_FAILD   = "MERCHANT_PRODUCTS_FAILD";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILD   = "GET_CATEGORY_FAILD";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAILD   = "PRODUCT_CREATE_FAILD";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAILD   = "ADD_ROLE_FAILD";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAILD   = "GET_ROLE_FAILD";
export const ROLE_DATA_SUCCESS = "ROLE_DATA_SUCCESS";
export const ROLE_DATA_FAILD   = "ROLE_DATA_FAILD";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_FAILD   = "USER_DATA_FAILD";
export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS";
export const GET_BRAND_FAILD   = "GET_BRAND_FAILD";
export const GET_SUBCATEGORY_SUCCESS = "GET_SUBCATEGORY_SUCCESS";
export const GET_SUBCATEGORY_FAILD   = "GET_SUBCATEGORY_FAILD";
export const PRODUCTS_STATUS_UPDATE_FAILD   = "PRODUCTS_STATUS_UPDATE_FAILD";
export const PRODUCTS_STATUS_UPDATE_SUCCESS   = "PRODUCTS_STATUS_UPDATE_SUCCESS";
export const ALL_NOTIFICATIONS_NEW  = "ALL_NOTIFICATIONS_NEW";
export const ALL_NOTIFICATIONS_OLD = "ALL_NOTIFICATIONS_OLD";


//export const GET_REFUNDCANCLE_SUCCESS = "GET_REFUNDCANCLE_SUCCESS";
//export const GET_REFUNDCANCLE_FAILD = "GET_REFUNDCANCLE_FAILD";
export const GET_FAQ_QUESTIONS_SUCCESS =  "GET_FAQ_QUESTIONS_SUCCESS";
export const GET_FAQ_QUESTIONS_FAILD =  "GET_FAQ_QUESTIONS_FAILD";
export const   GET_LEAD_FUNNEL_DATA_FAILD = "GET_LEAD_FUNNEL_DATA_FAILD";
export const   GET_LEAD_FUNNEL_DATA_SUCCESS = "GET_LEAD_FUNNEL_DATA_SUCCESS";
export const GET_REFUNDCANCLE_SUCCESS = "GET_REFUNDCANCLE_SUCCESS";
export const GET_REFUNDCANCLE_FAILD = "GET_REFUNDCANCLE_FAILD";
export const   GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const   GET_DASHBOARD_FAILD = "GET_DASHBOARD_FAILD";
export const ACTIVE_SETTLEMENT = "ACTIVE_SETTLEMENT";

export const   SEND_SETTLEMENT_DATA = "SEND_SETTLEMENT_DATA";
export const SEND_SETTLEMENT_DUE_DATA = "SEND_SETTLEMENT_DUE_DATA";
export const SEND_SETTLEMENT_REFUND_DATA = "SEND_SETTLEMENT_REFUND_DATA";
  export const SEND_PRODUCT_ALL = "SEND_PRODUCT_ALL";

export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAILED = "GET_REPORT_FAILED";
export const ALL_NOTIFICATIONS = "ALL_NOTIFICATIONS";

export const GET_SALES_INSIGHT_GRAPH_DATA_SECCESS = "GET_SALES_INSIGHT_GRAPH_DATA_SECCESS";
export const GET_SALES_INSIGHT_GRAPH_DATA_FAILD = "GET_SALES_INSIGHT_GRAPH_DATA_FAILD";
export const GET_USER__DATA_TO_UPDATE_SECCESS = "GET_USER__DATA_TO_UPDATE_SECCESS";
export const GET_USER__DATA_TO_UPDATE_FAILD = "GET_USER__DATA_TO_UPDATE_FAILD";
export const GET_PRODUCT_UN_DATA = "GET_PRODUCT_UN_DATA";
export const ORDER_SUMMARY = "ORDER_SUMMARY";
export const ORDER_SUMMARY_EMPTY = "ORDER_SUMMARY_EMPTY";



export const GET_GLOBALSEARCH_SUCCESS = "GET_GLOBALSEARCH_SUCCESS"
export const GET_GLOBALSEARCH_FAILD = "GET_GLOBALSEARCH_FAILD"
export const LEAD_DATA_LIST = "LEAD_DATA_LIST"
export const BULK_UPDATE = "BULK_UPDATE"
export const BULK_LEAD_CREATE = "BULK_LEAD_CREATE"
export const SHOW_SEARCHED = "SHOW_SEARCHED"
export const ACTIVE_PRODUCT_TAB = "ACTIVE_PRODUCT_TAB"
export const LOAD_PRODUCT_DATA = "LOAD_PRODUCT_DATA"
export const ADD_EMAIL_RECIPIENT = "ADD_EMAIL_RECIPIENT"
export const EMAILS_GROUP_LIST = "EMAILS_GROUP_LIST"
export const CREAT_LEAD_SOFT_PULL = "CREAT_LEAD_SOFT_PULL"
export const SETTLEMENT_TYPE = "SETTLEMENT_TYPE"
export const ACTIVE_WIZR_TAB = "ACTIVE_WIZR_TAB"
export const SEARCH_FIELD = "SEARCH_FIELD"
export const DASHBOARD_COUNT_WIZR = "DASHBOARD_COUNT_WIZR"
export const TIME_FILTER_WIZR = "TIME_FILTER_WIZR"


export const GET_SKILLS_LEADS_SUCCESS   = "GET_SKILLS_LEADS_SUCCESS";
export const GET_SKILLS_LEADS_FAILD     = "GET_SKILLS_LEADS_FAILD";

export const GET_APPROVED_LEADS_STATUS_SUCCESS   = "GET_APPROVED_LEADS_STATUS_SUCCESS";
export const GET_APPROVED_LEADS_STATUS_FAILD     = "GET_APPROVED_LEADS_STATUS_FAILD";

export const GET_REJECTED_LEADS_STATUS_SUCCESS   = "GET_REJECTED_LEADS_STATUS_SUCCESS";
export const GET_REJECTED_LEADS_STATUS_FAILD     = "GET_REJECTED_LEADS_STATUS_FAILD";

export const GET_SELECTED_LEADS_DETAIL_SUCCESS =  "GET_SELECTED_LEADS_STATUS_SUCCESS";
export const GET_SELECTED_LEADS_DETAIL_FAILD =  "GET_SELECTED_LEADS_STATUS_FAILD";
export const GET_TOP_COURSES ="GET_TOP_COURSES"
export const GET_TOP_COURSES_FAILED ="GET_TOP_COURSES_FAILED"


export const GET_PAYMENT_STATUS_SUCCESS ="GET_PAYMENT_STATUS_SUCCESS"
export const GET_PAYMENT_STATUS_FAILD ="GET_PAYMENT_STATUS_FAILD"
export const SET_MERCHANT_JOURNEY = "SET_MERCHANT_JOURNEY"

export const GET_USER_PROFILE_SUCCESS ="GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAILD ="GET_PAYMENT_STATUS_FAILD"

export const GET_SELECTED_COURSE_DETAIL_SUCCESS="GET_SELECTED_COURSE_DETAIL_SUCCESS"
export const GET_SELECTED_NOTES_SUCCESS="GET_SELECTED_NOTES_SUCCESS"


