import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { closeModel } from "../actions/model";
import { getMerchantProductList, getCategory, createProduct, getMerchantProducts, getFilterMaster, UpdateCourseStatus, getCourseDownloadDetails, getMerchantProductsWizr, setCourseDetails } from "../actions/user";
import { openBulkNewProdModel } from "../actions/model";
import { Scrollbar } from "react-scrollbars-custom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import XlsxPopulate, { Promise } from "xlsx-populate";
import { saveAs } from "file-saver";
import readXlsxFile from 'read-excel-file'
import { finalCourseData, finalDate } from '../common/commonFunctions';
import { error } from 'jquery';
import Alert from '@mui/material/Alert';
import { history } from '../helpers/history'
import { logOut, logout } from '../actions/auth';




const SECTION1 = 0;
const SECTION2 = 1;
const SECTION3 = 2;
const initialState = {
  product: '',
  product_price: '',
  product_name: '',
  category: '',
  brand: '',
  description: '',
  isValid: true,
  errorMsg: '',
  isSuccess: '',
  successMsg: '',
  onBoarding: SECTION1,
  showErr: false,
  loanerrmsg: '',
  brandData: [],
  courseStage: 'Deactivate Course',
  finalTemplateData: [],
  courseStatusError: "",
  isCallApi: false,
};

class CourseDetails extends Component {

  constructor() {
    super()
    this.state = initialState;
    this.testRef = React.createRef();

  }

  componentDidMount() {

    let getProd = { merchant_id: this.props.user_id }
    // this.props.dispatch(getMerchantProductList(getProd));
    // this.props.dispatch(getCategory());

    // this.props.dispatch(getFilterMaster(localStorage.getItem('user_id'), 'brand')).then((response) => {
    //    const brand = [];
    //    const allbrand =  response?.data ? response.data : [];
    //    allbrand.forEach(element =>{ brand.push({ value:element.name, label:element.name})});
    //     this.setState({ brandData:brand })
    // })

    // const finalD = finalCourseData();
    // this.setState({finalTemplateData :finalD})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.add_new_product_show !== this.props.add_new_product_show) {
      this.setState(initialState);
    }
  }

  onNextBording = (next) => {
    this.setState({ onBoarding: next });
  }

  closeModel = () => {
    this.props.dispatch(closeModel())
  }
  // openSuccessModel = () => {
  //     this.props.dispatch(openSuccessModel())
  // }


  openNewbulkUpload_modal = (e) => {
    e.preventDefault();
    this.props.dispatch(openBulkNewProdModel())
  }

  handleChange = (e) => {
    // this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'brand') {

      this.setState({ [e.target.name]: e.target.value.replace(/[^a-z]/gi, '') });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  handleClose = () => {
    const { sfid, courseStatus } = this.props;
    if (this.state.isCallApi) {
      let getProd = {
        merchant_id: sfid,
        page: 1,
        limit: 10,
        filter: {
          search: "",
          productStatus: courseStatus,
          course_type: "",
          course_delivery_mode: "", //Online, Offline
          medium_of_instruction__c: "",
          listing_time: "",
        }
      }
      this.props.dispatch(getMerchantProductsWizr(getProd)).catch(error => {
        if (error?.status === 401) {
          this.props.dispatch(logout());
          history.push('/login');
        }
      })
    }
    this.setState(initialState);
  }


  onlyNumbers = (e) => {
    var pattern = new RegExp(/^(?!(0))\d+$/);
    if (e.target.value !== '') {
      if (!pattern.test(e.target.value)) {
        // this.setState({ [e.target.name]: "" });
      } else {
        this.setState({ [e.target.name]: e.target.value });
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  // onlyNumbers = (e) => {
  //     var pattern = new RegExp(/^[0-9\b]+$/);
  //     if(e.target.value !=='')
  //     {
  //       if(!pattern.test(e.target.value))
  //       {
  //         this.setState({[e.target.name] : ""});
  //       }else{
  //         this.setState({[e.target.name] : e.target.value});
  //       }
  //     }else{
  //       this.setState({[e.target.name] : e.target.value});
  //     }
  //   }

  handleSelectProduct = (e) => {
    const { mproducts } = this.props;
    let id = e.value;
    if (id != '') {
      var selctedItem = mproducts.find(item => item.sfid == id);
      this.setState({ product: id, product_price: selctedItem.mrp__c, category: selctedItem.product_sub_category__c, product_name: selctedItem.name, brand: selctedItem.brand__c });
      this.setState({ onBoarding: SECTION2 });
    } else {
      this.setState({ product_price: '', product: '', product_name: '', brand: '' });
    }
  }
  scrollToBottom = () => {
    var objDiv = document.getElementById("create-lead");
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  handleSubmitProd = (e) => {
    e.preventDefault();
    let data = {
      product_id: this.state.product ? this.state.product : '',
      price: this.state.product_price,
      product_name: this.state.product_name,
      brand: this.state.brand,
      category: this.state.category,
      description: this.state.description,
      merchant_id: this.props.sfid
    };
    this.props.dispatch(createProduct(data)).then((response) => {
      if (response.status === "success") {
        this.setState({ isSuccess: 1, onBoarding: SECTION3, successMsg: response.message });
        // setInterval(document.getElementById("close-create").click(), 5000);
        let getProd = { merchant_id: this.props.user_id }
        this.props.dispatch(getMerchantProductList(getProd));
        //window.location.reload();

      } else {
        if (response.status === "error") {
          this.setState({ showErr: true, loanerrmsg: response.message })
        }
        else {
          this.scrollToBottom();
          this.setState({ isSuccess: 0, onBoarding: SECTION1, successMsg: response.message });
        }

      }
    }).catch((error) => {
      this.setState({ isSuccess: 0, onBoarding: SECTION1, successMsg: error });
    });

  }
  /*
  url=https://eduvanz-api-01.herokuapp.com/api/create_product
  brand: "bajaj"
category: "2-Wheelers"
description: ""
merchant_id: "00171000008GurGAAS"
price: "21000"
product_id: "01t7100DMTTT10313"
product_name: "Demo Test 314"
  
  */



  handleDownload = (url) => {

    let obj = { merchant_id: this.props.user_id, "section": '' }
    let getProd = { merchant_id: this.props.user_id }
    const { mproducts } = this.props;
    //  this.props.dispatch(merchantProductDownload(obj)).then((response) => {
    // this.props.dispatch(getMerchantProductList(getProd)).then((response) => {
    // if (response.proData && response.proData.length > 0) {

    let getData = []
    const m_id = localStorage.getItem("sfid") ? localStorage.getItem("sfid") : localStorage.getItem("sfid")
    for (let i = 0; i < mproducts.length; i++) {
      let source = mproducts[i];

      let obj = {
        // name:source.name,
        // // merchant_id : source.id,
        // merchant_id : m_id,
        // product_id : source.sfid,
        // new_selling_price : source.offer_price__c,
        // activation_status : source.activate_product__c,
        // sku:source.sku,

        merchant_id: m_id,
        product_id: source.sfid,
        new_selling_price: source.price__c,
        activation_status: 'active',
        brand: '',
        name: source.name,
        description: '',
        category: source.product_sub_category__c
        // merchant_id : source.id,
      }
      getData.push(obj)
    }

    this.saveAsExcel(getData);
  }
  //     });
  // }





  // saveAsExcel = async (getData) => {
  //     var data = [];
  //     await Promise.all(getData.map(async (elament) => {
  //         const productDet = elament
  //         data.push({
  //             merchant_id: productDet && productDet.merchant_id && productDet.merchant_id != null ? productDet.merchant_id : '',
  //             P_id: productDet && productDet.product_id ? productDet.product_id : '',
  //             // category: productDet && productDet.product_sub_category ? productDet.product_sub_category : '',
  //             // mrp: productDet && productDet.mrp && productDet.mrp != null ? productDet.mrp : '',
  //             // mop: productDet && productDet.mop && productDet.mop != null ? productDet.mop : '',
  //             selling_price: productDet && productDet.new_selling_price && productDet.new_selling_price != null ? productDet.new_selling_price : '',
  //             activation_status: productDet && productDet.activation_status == false ? 'inactive' : 'active',
  //             name: productDet && productDet.name ? productDet.name : '',
  //             sku: productDet && productDet.sku && productDet.sku != null ? productDet.sku : '',
  //         })
  //     }));
  //     let header = ["Merchant Id", "Product Id", "Selling Price", "Activation Status", "Name", "SKU"];
  //     //let header = ["Category","Sub Category","Brand","Product Name","Selling Price","Description"];
  //     XlsxPopulate.fromBlankAsync().then(async (workbook) => {
  //         const sheet1 = workbook.sheet(0);
  //         const sheetData = await this.getSheetData(data, header);
  //         const totalColumns = sheetData[0].length;
  //         sheet1.cell("A1").value(sheetData);
  //         const range = sheet1.usedRange();
  //         const endColumn = String.fromCharCode(64 + totalColumns);
  //         sheet1.row(1).style("bold", true);
  //         sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
  //         range.style("border", true);
  //         return workbook.outputAsync().then((res) => {
  //             saveAs(res, "product_report.xlsx");
  //         });
  //     });
  // }

  // getSheetData = async (data, header) => {
  //     var fields = Object.keys(data[0]);
  //     var sheetData = data.map(function (row) {
  //         return fields.map(function (fieldName) {
  //             return row[fieldName] ? row[fieldName] : "";
  //         });
  //     });
  //     sheetData.unshift(header);
  //     return sheetData;
  // }

  saveAsExcel = async (getData) => {
    var data = [];
    await Promise.all(getData.map(async (elament) => {
      const productDet = elament
      data.push({
        merchant_id: productDet && productDet.merchant_id && productDet.merchant_id != null ? productDet.merchant_id : '',
        P_id: productDet && productDet.product_id ? productDet.product_id : '',
        // category: productDet && productDet.product_sub_category ? productDet.product_sub_category : '',
        // mrp: productDet && productDet.mrp && productDet.mrp != null ? productDet.mrp : '',
        // mop: productDet && productDet.mop && productDet.mop != null ? productDet.mop : '',
        selling_price: productDet && productDet.new_selling_price && productDet.new_selling_price != null ? productDet.new_selling_price : '',
        activation_status: 'Active',
        brand: productDet && productDet.brand && productDet.brand != null ? productDet.brand : '',
        name: productDet && productDet.name ? productDet.name : '',
        description: productDet && productDet.description && productDet.description ? productDet.description : '',
        category: productDet && productDet.category && productDet.category != null ? productDet.category : '',
      })
    }));
    let header = ["Merchant Id", "Product Id", "Selling Price", "Activation Status", "Brand", "Name", "Description", "Category"];
    //let header = ["Category","Sub Category","Brand","Product Name","Selling Price","Description"];
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = await this.getSheetData(data, header);
      const totalColumns = sheetData[0].length;
      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "product_report.xlsx");
      });
    });
  }

  getSheetData = async (data, header) => {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  selectBrand = (selectedOptions) => {
    this.setState({ brand: selectedOptions.value });
  }


  generateCsv = async () => {
    const { courseDetail, sfid } = this.props;
    const downloadData = {
      merchant_id: sfid,
      merchant_product_id: courseDetail.product_sfid,
    }
    const res = await this.props.dispatch(getCourseDownloadDetails(downloadData))
    const coursed = finalCourseData(res.proData[0])
    if (coursed) {
      let header = ["", "Course Name", "Course Fee", "Course ID", "Medium", "Subject", "Respective Skills", "Batch Start Date", "Last Enrolment Date", "Course Level", "Course Eligibility Required", "Scholarship", "Certificate Provided", "Certificate Providing Authority", "Course Duration", "Delivery Mode", "Course Learning Mode", "Course/ Class Location", "Type", "Syllabus"];
      XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const sheetData = await this.getSheetData(coursed, header);
        const totalColumns = sheetData[0].length;
        sheet1.cell("A1").value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.row(2).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill", "fcdeb8");
        sheet1.range("A2:A3").style({ bold: true, fill: "D3D3D3" });
        //   sheet1.column("A1:" + endColumn + "1").width(25).hidden(false);
        sheet1.range("A1:A1").style("fill", "FFFFFF");
        sheet1.range("B2:N2").style("fill", "F1E5AC");
        sheet1.range("O2:S2").style("fill", "ddfada");
        sheet1.range("T2:T2").style("fill", "e4f2f7");
        range.style("border", true);


        //merge cellss 
        const range1 = workbook.sheet(0).range("B1:N1");
        range1.value("Basic Details");
        range1.style({ horizontalAlignment: "center", verticalAlignment: "center", fill: "f0bf60" })
        range1.merged(true);

        const range2 = workbook.sheet(0).range("O1:S1");
        range2.value("Duration & Delivery");
        range2.style({ horizontalAlignment: "center", verticalAlignment: "center", fill: "BDF5BD" })
        range2.merged(true);

        const range3 = workbook.sheet(0).range("T1:T1");
        range3.value("Syllabus");
        range3.style({ horizontalAlignment: "center", verticalAlignment: "center", fill: "ADD8E6" })
        range3.merged(true);

        return workbook.outputAsync().then((res) => {
          saveAs(res, "CourseDetail.xlsx");
          this.setState({ showDownloadCsvToast: true })
          setTimeout(() => {
            this.setState({ showDownloadCsvToast: false })
          }, 3000);
        });
      });
    }

    //   }
    // });
  }

  changeStatus = (statusValue) => {
    const { courseDetail, sfid, courseStatus } = this.props;
    const data = {
      merchant_id: sfid,
      merchant_product_id: courseDetail.merchant_product_sfid,
      status: statusValue
    }
    this.props.dispatch(UpdateCourseStatus(data)).then(response => {

      if (response?.data?.status === "success") {
        this.setState({ isCallApi: true })
        const st = statusValue === "Inactive" ? "Inactive Pending" : "Active Pending"
        this.props.dispatch(setCourseDetails({ ...courseDetail, activate_product__c: st }, courseStatus))
      }
      if (response?.status === "error") {
        this.setState({ courseStatusError: response.message })
        setTimeout(() => {
          this.setState({ courseStatusError: "" })
        }, 1000);
      }
      // this.props.dispatch(setCourseDetails(item))
    })
  }



  render() {
    const { isLoading, mproducts, category, singleProduct, courseDetail, courseStatus } = this.props;
    // let prodOptions = [];
    // if (mproducts) {
    //     for (var i = 0; i < mproducts.length; i++) {
    //         let pname = String(mproducts[i].name).slice(0, 15);
    //         prodOptions.push({ value: mproducts[i].sfid, label: pname });
    //     }
    // }
    // let item = singleProduct?.proData[0] ? singleProduct.proData[0] : {};
    let item = courseDetail ? courseDetail : {};
    return (
      <>
        {/* {isLoading?(
                <div className="loading">Loading&#8230;</div>
            ):''} */}
        {/* Modal */}
        <div className="modal right fade myModal" id="myModal22" role="dialog"  data-backdrop="static" data-keyboard="false" >
          <div className="modal-dialog">
            {this.state.courseStatusError && <div className="course-err-container">
              <Alert severity="error">{this.state.courseStatusError}</Alert>
            </div>}
            {/* Modal content*/}

            <form onSubmit={(e) => e.preventDefault()} className="f_height">
              <div className="modal-content">
                
                <Scrollbar scrollTop={0}>
                  <div className="modal-header p-0 add-course-header">
                    <div className='course-close-btn'>
                      <button onClick={this.generateCsv} type="button" id="close-create" className="close mr-5" > <img src='./images/download.svg' style={{ fontSize: "16px", height: '39px' }}></img> </button>
                      {/* <button onClick={this.handleClose} type="button" id="close-create" className="close" data-dismiss="modal"> <i className="fas fa-times" style={{ fontSize: "25px" }}></i> </button> */}
                      <button type="button" className="close" id="close-create" data-dismiss="modal" onClick={this.handleClose}> <img src='../img/popclose.svg' alt='close' /> </button>
                    </div>


                    <div className='d-flex justify-content-between align-items-center w-100'>
                      <div className='d-flex align-items-center'>
                        <h5 className="modal-title fz24 p-0 add-course-title color1D20">Course Details</h5>
                        <span id="coursemodel" className='invisible' data-dismiss="modal"></span>
                        {
                          // this.state.courseStage === 'Activate Course' || this.state.courseStage === 'Activation Pending'  ? <button className='course-Inactive-btn'>Inactive</button> :<button className='course-active-btn'>Active</button>
                          (item?.activate_product__c === "Inactive" || item?.activate_product__c === "Active Pending") ? <button className='course-Inactive-btn'>Inactive</button> : <button className='course-active-btn'>Active</button>
                        }

                      </div>
                      {/* {this.state.courseStage === "Deactivate Course" ? 
                                       <div className='deactivate-course-btn' onClick={()=>this.setState({courseStage :'Deactivation Pending'})}><img src='./images/deactive_dash.svg' style={{height:'19px'}}></img> <span>Deactivate Course</span></div>
                                        : this.state.courseStage === 'Deactivation Pending' ?
                                       <div className='deactivate-pending-course-btn' onClick={()=>this.setState({courseStage :'Activate Course'})}><img className='hoverImg2' src='./images/deactivationPending.svg' style={{height:'19px'}}></img><img className='hoverImg' src='./images/blackpending.svg' style={{height:'19px'}}></img><span>Deactivation Pending</span></div>
                                        : this.state.courseStage === 'Activate Course' ?
                                       <div className='activate-course-btn' onClick={()=>this.setState({courseStage :'Activation Pending'})}><img className='hoverImg2' src='./images/activate-course.svg' style={{height:'19px'}}></img><img className='hoverImg' src='./images/balckActive.svg' style={{height:'19px'}}></img><span>Activate Course</span></div>
                                        :
                                       <div className='deactivate-pending-course-btn' onClick={()=>this.setState({courseStage :'Deactivate Course'})}><img className='hoverImg2' src='./images/deactivationPending.svg' style={{height:'19px'}}></img><img className='hoverImg' src='./images/blackpending.svg' style={{height:'19px'}}></img><span>Activation Pending</span></div>

                                      } */}
                      {item?.activate_product__c === "Inactive" && <div className='activate-course-btn' onClick={() => this.changeStatus("Active")}><img className='hoverImg2' src='./images/activate-course.svg' style={{ height: '19px' }}></img><img className='hoverImg' src='./images/balckActive.svg' style={{ height: '19px' }}></img><span>Activate Course</span></div>}
                      {item?.activate_product__c === "Active" && <div className='deactivate-course-btn' onClick={() => this.changeStatus("Inactive")}><img src='./images/deactive_dash.svg' style={{ height: '19px' }}></img> <span>Deactivate Course</span></div>}
                      {item?.activate_product__c === "Active Pending" && <div className='deactivate-pending-course-btn'><img className='hoverImg2' src='./images/deactivationPending.svg' style={{ height: '19px' }}></img><img className='hoverImg' src='./images/blackpending.svg' style={{ height: '19px' }}></img><span>Activation Pending</span></div>}
                      {item?.activate_product__c === "Inactive Pending" && <div className='deactivate-pending-course-btn'><img className='hoverImg2' src='./images/deactivationPending.svg' style={{ height: '19px' }}></img><img className='hoverImg' src='./images/blackpending.svg' style={{ height: '19px' }}></img><span>Deactivation Pending</span></div>}
                    </div>
                  </div>

                  <div id="" className="modal-body pt-0 px-0">

                    {/* commenting because we will do add and edit course in next phase */}
                    {/* <div className='d-flex flex-column add-course-body pt-0 pb-0' style={{backgroundColor:"#F2F3F8",border:'1px solid #E6E6E6'}} > 
                                           <div className='d-flex flex-column'>
                                           <div className="container p-0">


<div className="row">
  <div className="col-12">
    <div id="accordionExample" >
    <div className="card course-edit-accordian" ba>
        <div id="headingEdit" className="card-header card-header-st">
          <h2 className="mb-1">
            <button type="button" data-toggle="collapse" data-target="#collapseEdit" aria-expanded="false" 
              aria-controls="collapseEdit"
              className="btn-link text-dark font-weight-bold collapsible-link accordian-btn">How to ‘Edit & Update’ course details</button>
          </h2>
        </div>
        <div id="collapseEdit" aria-labelledby="headingEdit" data-parent="#accordionExample" className="collapse">
          <div className="card-body pright32">
                    
                
          <div className='course-edit-container mt-0 pr-0'>
                                                <div className='flexSize1'>
                                                   <p className='course-all-labels steps'>Step 1</p>
                                                   <p className='stepsPara'>Download the Excel file via download (  <img src='./images/download.svg' style={{height:'1.464vw',width:"2.6042vh"}}/>  ) button on the top.</p>
                                                </div>
                                                <div className='flexSize1'>
                                                   <p className='course-all-labels steps'>Step 2</p>
                                                   <p className='stepsPara'>Update the details on the same file.</p>
                                                </div>
                                                <div className='flexSize1'>
                                                   <p className='course-all-labels steps'>Step 3</p>
                                                   <p className='stepsPara'>Upload the file via ‘Add Course’</p>
                                                </div>
                                                </div>
                
                
                
                
                
                </div>                        
                </div>                        
                </div>                        
                </div>                        
                </div>                        
                </div>                        
                </div>                        
                                           
                                           
                                           </div>
                                         </div> */}

                    <div className='add-course-body ' style={{ border: '1px solid #E6E6E6' }}>

                      <div className=''>
                        <h6 className='font-weight-bold marginb24' >Basic Details</h6>
                        <div className='row'>
                          <div class="col-sm-12">
                            <p className='course-text-light  pt0'>Course Name</p>
                            <p className='or'>{item?.product_name ? item.product_name : "-"}</p>
                          </div>
                        </div>

                        <div className='row mt20'>
                          <div class="col-sm-4">
                            <p className='course-text-light'>Course Fee</p>

                            <p className='course-all-labels'>{item.price_on_request ? "On Request" : item?.offer_price__c && `₹ ${item.offer_price__c.toLocaleString('en-IN')}`}</p>
                          </div>
                          <div className='col-sm-4'>
                            <p className='course-text-light'>Medium</p>

                            <p className='course-all-labels'>{item.medium_of_instruction__c ? item.medium_of_instruction__c : "-"}</p>
                          </div>
                          <div className='col-sm-4'>
                            <p className='course-text-light'>Subject</p>

                            <p className='course-all-labels'>{item.subject__c ? item.subject__c : "-"}</p>
                          </div>
                        </div>
                        <div className='row mt20'>
                          <div className='col-sm-12'>
                            <p className='course-text-light fz14'>Respective Skills</p>
                            {item.skills__c ? <div className='course-skill-label-container'>
                              {
                              item.skills__c.map(skill => {
                                return(
                                  <p className='course-skill-label'>{skill}</p>
                                )
                              })
                              }
                            </div> : "-"}
                          </div>
                        </div>



                        <div className='row mt20'>
                          <div className='col-sm-4'>
                            <p className='course-text-light'>Batch Start Date</p>

                            <p className='course-all-labels'>NA</p>
                          </div>
                          <div className='col-sm-4'>
                            <p className='course-text-light'>Last Enrolment Date</p>

                            <p className='course-all-labels'>NA</p>
                          </div>
                          <div className='col-sm-4'>
                            <p className='course-text-light'>Course Level</p>

                            <p className='course-all-labels'>{item.course_level__c ? item.course_level__c : "-"}</p>
                          </div>
                        </div>

                        <div className='row mt20'>
                          <div className='col-sm-12'>
                            <p className='course-text-light'>Course Eligibility Required</p>

                            <p className='course-all-labels'>{item.course_eligibility__c ? item.course_eligibility__c : "-"}</p>
                          </div>
                        </div>

                        <div className='row mt20'>
                          <div className='col-sm-12'>
                            <p className='course-text-light'>Course Eligibility</p>
                            {item?.course_eligibility__c?.toLowerCase() === "yes" ? <>
                              <p className='course-eligibility fz14 mr-1'>Education</p>
                              <p className='course-eligibility fz14 mr-1'>Work exp.</p>
                              <p className='course-eligibility fz14 mr-1'>Eligibility test</p>
                            </>
                              : "-"}
                          </div>
                        </div>

                        <div className='row mt20'>
                          <div className='col-sm-12'>
                            <p className='course-text-light'>Scholorship</p>

                            <p className='course-all-labels'>{item.scholarship__c ? item.scholarship__c : "-"}</p>
                          </div>
                        </div>


                        <div className='row mt20'>
                          <div className='col-sm-4'>
                            <p className='course-text-light'>Certificate Provided</p>

                            <p className='course-all-labels'>{item.certificate_availability__c ? item.certificate_availability__c : "-"}</p>
                          </div>
                          <div className='col-sm-4'>
                            <p className='course-text-light'>Certificate Providing Authority</p>

                            <p className='course-all-labels'>{item.certificate_providing_authority__c ? item.certificate_providing_authority__c : "-"}</p>
                          </div>
                        </div>




                      </div>
                    </div>


                    {/* duration and delivery */}

                    <div className='add-course-body' style={{ border: '1px solid #E6E6E6' }}>

                      <div>
                        <h6 className='font-weight-bold mt-2' >Duration & Delivery</h6>
                        <div className='row mt20'>
                          <div className='col-sm-4'>
                            <p className='course-text-light'>Course Duration</p>
                            <p className='course-all-labels'>{item.course_duration__c ? item.course_duration__c : "-"}</p>

                          </div>
                          <div className='col-sm-4'>
                            <p className='course-text-light'>Delivery Mode</p>
                            <p className='course-all-labels'>{item.course_delivery_mode__c ? item.course_delivery_mode__c : "-"}</p>
                          </div>
                          <div className='col-sm-4'>
                            <p className='course-text-light'>Course Learning Mode</p>
                            <p className='course-all-labels'>{item.course_learning_mode__c ? item.course_learning_mode__c : "-"}</p>

                          </div>
                        </div>
                        <div className='row mt20'>
                          <div className='col-sm-4'>
                            <p className='course-text-light'>Course/Class location</p>
                            <p className='course-all-labels'>{item.course_class_location__c ? item.course_class_location__c : "-"}</p>
                          </div>
                          <div className='col-sm-4'>
                            <p className='course-text-light' >Type</p>
                            <p className='course-all-labels'>{item.fulltime_parttime__c ? item.fulltime_parttime__c : ""}</p>
                          </div>


                        </div>




                      </div>




                    </div>


                    {/* Syllabus */}
                    <div className='d-flex flex-column add-course-body' style={{ border: '1px solid #E6E6E6' }}>

                      <div className='d-flex flex-column'>
                        <div className="container p-0">



                          <div className="row">
                            <div className="col-12">
                              <h6 className='font-weight-bold course-syllabus-heading'>Syllabus</h6>
                              {/* <p  className='course-all-labels'>{item.course_syllabus__c ? item.course_syllabus__c : "-"}</p> */}
                              {
                                item.course_syllabus__c ?
                                  // <p dangerouslySetInnerHTML={{ __html: item.course_syllabus__c && item.course_syllabus__c }} className='course-all-labels'></p>
                                  item.course_syllabus__c.split("<br>").map(row => { 
                                    if(row){
                                      return(
                                        <div className='course-module-content marginb24'>
                                          <span className='green-circle'></span>
                                          <span className='course-number color50495A'>{row}</span>
                                          {/* <span className='course-number color50495A'>{row.split('-')[0] ? row.split('-')[0] : ""}</span>
                                          <span className='course-names'>{row.split('-')[1] ? row.split('-')[1] : ""}</span> */}
                                        </div>
                                      )
                                    }                                                                       
                                  })
                                  : "-"
                              }
                              {/* <div id="accordionExample" style={{marginTop:"30px"}} >

     
      <div className="card syllabus-card">
        <div id="headingOne" className="card-header card-header-st">
          <h2 className="mb-0">
            <button type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
              aria-controls="collapseOne"
              className="btn-link font-weight-bold text-uppercase collapsible-link accordian-common-btn paddingb16 flexcenter">Semester 1</button>
          </h2>
        </div>
        <div id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample" className="collapse">
          <div className="card-body">
             <div>
                 
                <div className='marginb16'>
                   <span className='course-number'>Module 1</span> <span className='module-desc'>Lorem Ipsum</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
             <div>
                 
                <div className='marginb16'>
                   <span className='course-number'>Module 2</span> <span className='module-desc'>Lorem Ipsum</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>

             <div>
                 
                <div className='marginb16'>
                   <span className='course-number'>Module 3</span> <span className='module-desc'>Lorem Ipsum</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>

          </div>
        </div>
      </div>
      <div className="card syllabus-card">
        <div id="headingTwo" className="card-header card-header-st">
          <h2 className="mb-0">
            <button type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
              aria-controls="collapseTwo"
              className="btn-link font-weight-bold text-uppercase collapsible-link accordian-common-btn paddingb16 flexcenter">Semester 2</button>
          </h2>
        </div>
        <div id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordionExample" className="collapse">
          <div className="card-body">
             <div>
                 
                <div className='marginb16'>
                   <span className='course-number'>Module 1</span> <span className='module-desc'>Lorem Ipsum</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A color50495A'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
             <div>
                 
                <div className='marginb16'>
                   <span className='course-number'>Module 2</span> <span className='module-desc'>Lorem Ipsum</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>

             <div>
                 
                <div className='marginb16'>
                   <span className='course-number'>Module 3</span> <span className='module-desc'>Lorem Ipsum</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number  color50495A'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>

          </div>
        </div>
      </div>
      <div className="card syllabus-card">
        <div id="headingThree" className="card-header card-header-st">
          <h2 className="mb-0">
            <button type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
              aria-controls="collapseThree"
              className="btn-link font-weight-bold text-uppercase collapsible-link accordian-common-btn paddingb16 flexcenter">Semester 3</button>
          </h2>
        </div>
        <div id="collapseThree" aria-labelledby="headingThree" data-parent="#accordionExample" className="collapse">
          <div className="card-body">
             <div>
                 
                <div className='marginb16'>
                   <span className='course-number'>Module 1</span> <span className='module-desc'>Lorem Ipsum</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
             <div>
                 
                <div className='marginb16'>
                   <span className='course-number'>Module 2</span> <span className='module-desc'>Lorem Ipsum</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>

             <div>
                 
                <div className='marginb16'>
                   <span className='course-number'>Module 3</span> <span className='module-desc'>Lorem Ipsum</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>

          </div>
        </div>
      </div>     
      <div className="card syllabus-card">
        <div id="headingFour" className="card-header card-header-st">
          <h2 className="mb-0">
            <button type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
              aria-controls="collapseFour"
              className="btn-link font-weight-bold text-uppercase collapsible-link accordian-common-btn paddingb16 flexcenter">Semester 4</button>
          </h2>
        </div>
        <div id="collapseFour" aria-labelledby="headingFour" data-parent="#accordionExample" className="collapse">
          <div className="card-body">
             <div>
                 
                <div className='marginb16'>
                   <span className='course-number'>Module 1</span> <span className='module-desc'>Lorem Ipsum</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
             <div>
                 
                <div className='marginb16'>
                   <span className='course-number'>Module 2</span> <span className='module-desc'>Lorem Ipsum</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>

             <div>
                 
                <div className='marginb16'>
                   <span className='course-number'>Module 3</span> <span className='module-desc'>Lorem Ipsum</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content marginb24'>
                 <span className='green-circle'></span>
                 <span className='course-number color50495A'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>

          </div>
        </div>
      </div>

      
      

    </div> */}
                            </div>
                          </div>
                        </div>




                      </div>
                    </div>




                  </div>
                </Scrollbar>
              </div>
            </form>
          </div >
        </div >

        {/*Model Stop*/}
      </>
    )
  }

}

function mapStateToProps(state) {
  const { add_new_product_show } = state.model;
  const { isLoading, user_id, sfid } = state.auth;
  const { mproducts, category, singleProduct, courseDetail, courseStatus } = state.user;
  return {
    add_new_product_show,
    user_id,
    mproducts,
    isLoading,
    sfid,
    category,
    singleProduct,
    courseDetail,
    courseStatus
  };
}

export default connect(mapStateToProps)(CourseDetails)