import React, { Component } from "react";
import $ from 'jquery';
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Redirect } from "react-router-dom";
import Pagination from '@material-ui/lab/Pagination';
import { getLeads, getLeadsCount, getMerchantProducts, merchantLeadDownload, setLeadTab, setShowSearched, setSettlementclickItemId, getSkillsLeadsData, setLeadDetails, getOnholdRejectLeadStatusData, getApproveLeadStatusData, clearApprovalOnholdRejectErr, getPaymentStatus, setLeadDetailsFailure, notesApi } from "../actions/user";
import { salesForceLogin, logOut } from "../actions/auth";
import XlsxPopulate, { Promise } from "xlsx-populate"
import { saveAs } from "file-saver"
import jsPDF from 'jspdf'
import "jspdf-autotable"
import Filter from "../common/filter";
import FilterMain from "./filter-main.component";
import UserProfilefilter from "./userProfilefilter.component";
import Sidebar from "../common/sidebar";
import {
    openBulkModel,
    updateProId,
    openLeadProfileModel,
    openFilterModel,
    openEmailModel,
    openRequestModel,
    openPreviewModel,
    openSuccessModel,
    openCreateLeadModel,
    openLeadApplicationModel,
    openLeadDetailModel,
    closeLeadApplicationModel
} from "../actions/model"
import Topbar from "../common/topbar";
import TopWizrbar from "./TopWizrbar";
import CenteredPopUp from "../common/CenteredPopUp";
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextareaAutosize } from '@mui/base';
import TopBarWizrHelpNotification from "./TopBarWizrHelpNotification";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TablePagination from '@mui/material/TablePagination';
import moment from 'moment';
import tableSort from "../common/tableSort"
import dayjs from 'dayjs';

// import {
// MuiPickersUtilsProvider,
// KeyboardDatePicker
// } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
import { styled } from '@mui/material/styles';
import NoRecordsFound from "../common/NoRecordsFound.component";
import ProductFilter from "./ProductFilter.component";
import LeadSkillFilter from "./LeadSkillFilter";
// import { TextField } from "@mui/material";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { history } from '../helpers/history';

const StyledDatePicker = styled(DesktopDatePicker)(
    ({ theme }) => ({
        border: '1px solid black',
        borderRadius: '7px',
        // width: '59%',
        '&:hover': {
        },
    }),
);


const StyledTextArea = styled(TextareaAutosize)(
    ({ theme }) => ({
        width: '60%',
        border: '1px solid black',
        borderRadius: '7px',
    }),
);

class Leads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
            page: 0,
            limit: 10,
            stage: 'Pending',
            showtabledata: false,
            showFilterModal: false,
            showApprovedCenteredPopUp: false,
            showonholdCenteredPopUp: false,
            showrejectedCenteredPopUp: false,
            whiteRequestbtn: false,
            clearfilterValue: false,
            isBatchStartdate: false,
            BatchStartDate: '',
            BatchLastDate: '',
            isBatchLastdate: false,
            leadDataState: [],
            reasonOnhold: "",
            rejectedReason: "",
            errorMsg: "",
            selectedId: "",
            btnClickedArr: [],
            leadsData: [],
            productFilter: {
                duration: "",
                status: 'Pending',
                search: "",
                user_profile: "",
                gender: "",
                qualification: "",
                workExp: "",
                payment_request: "",
                course_fee: "",
                payment_status: "",
            },
            invalidStartDate: false,
            invalidEndDate: false,
            serachPage: 0,
            searchLimit: 10,
            resetPage: false,
            activeDropDown:null
            // closeMainModal:false
        };
        this.formatData = this.formatData.bind(this);
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        const { sfid, user_id, salesForceToken } = this.props
        // let objData = `stage=${this.state.stage}`;


        this.getLeadsData();
        let getProd = { merchant_id: this.props.user_id }
        //commented merchantProducts api because is unneccessary call
        // this.props.dispatch(getMerchantProducts(getProd));
        let obj = { id: this.props.user_id, token: this.props.token }
        if (!salesForceToken && this.props.merchant_journey == "B2B2C") {
            this.props.dispatch(salesForceLogin(obj));
        }

        $('#sidebarToggleTop').click(function () {
            $("#accordionSidebar").toggleClass("open-close")
        })

        this.setState({ leadsData: this.props?.leadSkills?.merchantLeads ? this.props?.leadSkills?.merchantLeads : [] })
         this.setState({clearfilterValue:!this.state.clearfilterValue})
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.leadSkills != this.props.leadSkills) {
            this.setState({ leadsData: this.props.leadSkills.merchantLeads })
        }
        if (prevProps.globalseraching != this.props.globalseraching) {
            this.setState({ serachPage: 0, searchLimit: 10 })
        }
        const { productFilter } = this.state;
        if (prevProps.searchedKeyword != this.props.searchedKeyword) {
            let d = { ...productFilter, search: this.props.searchedKeyword }
            this.setState({ productFilter: d })
        }

        if (prevState.resetPage != this.state.resetPage) {
            this.setState({ page: 0, limit: 10 })
        }
        
    }


    formatData = (dateIs) => {
        let forDate = dateIs.split('/')
        forDate = forDate[1] + '/' + forDate[0] + '/' + forDate[2]
        let newDate = new Date(forDate);
        let months = ["Jan", "Feb", "Mar", "April", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        let year = newDate.getFullYear();
        let date = newDate.getDate();
        let month = newDate.getMonth();
        month = months[month];
        return month + ' ' + date + ', ' + year;
    }

    handleTabChange = (stage) => {
        this.setState({
            stage: stage, leadsData: [], productFilter: {
                duration: "",
                status: stage,
                search: "",
                user_profile: "",
                gender: "",
                qualification: "",
                workExp: "",
                payment_request: "",
                course_fee: "",
                payment_status: "",
            }
        }, () => {
            this.getLeadsData(stage, 1, 10);
        });
        this.props.dispatch(setLeadTab(stage))
        this.props.dispatch(setShowSearched(false))

        // let objData = `stage=${stage}`;
        // stage 
        // Approved,Pending,Onhold,Rejected 
        // let objData = {
        //     merchant_id :"1",
        //     status: stage,
        //     page : 1,
        //     limit : 10,
        //     filter : [{
        //         user_profile : "",
        //         gender : "",
        //         qualification : "",
        //         workExp: ""
        //     }]
        // };
        this.setState({ page: 0, limit: 10 })
        // this.getLeadsData(stage, 1, 10);
        this.handleClearfilter();
    }

    getLeadsData = (stage, page, limit) => {
        const { sfid, user_id } = this.props
        const { productFilter } = this.state
        // this.props.dispatch(getLeads(getData, sfid));
        // this.props.dispatch(getLeadsCount(getData, sfid));
        const st = stage ? stage : this.state.stage;

        let objData = {
            merchant_id: sfid,
            // status: stage ? stage : this.state.stage,
            page: page ? page : this.state.page + 1,
            limit: limit ? limit : this.state.limit,
            filter: { ...productFilter, status: stage ? stage : this.state.stage }
        };
        this.props.dispatch(getSkillsLeadsData(objData)).then(response => {
            if (response?.status == 401) {
                this.props.dispatch(logOut());
                history.push('/login');
            }
        });
    }

    openCreateLeads = () => {
        this.props.dispatch(openCreateLeadModel());
    }

    openLeads = (sfid, opp_id, id) => {
        this.props.dispatch(setSettlementclickItemId(opp_id));
        this.props.dispatch(openLeadProfileModel(sfid, id));
        this.props.dispatch(updateProId(opp_id));
    }

    toggle1 = (e, id) => {
        if (e.target.className == "fa fa-angle-up") {
            e.target.style.display = "none";
            e.target.className = "fa fa-angle-down"
            e.target.style.display = "block";
            document.getElementById(id).style.display = "none"

        } else {
            e.target.style.display = "none";
            e.target.className = "fa fa-angle-up"
            e.target.style.display = "block";
            document.getElementById(id).style.display = "table-row";

        }

    }

    openLeadApplication = (item) => {
        // this.props.dispatch(openLeadApplicationModel(sfid , id));
        // this.props.dispatch(updateProId(opp_id));
        this.props.dispatch(openLeadDetailModel());
        this.props.dispatch(setLeadDetails({ item, status: this.state.stage }))
        this.props.dispatch(notesApi(item.cart_id))
    }

    openBulkModel = () => {
        this.props.dispatch(openBulkModel());
    }

    openFilter = () => {
        this.props.dispatch(openFilterModel());
    }

    openRequest = () => {
        this.props.dispatch(openRequestModel());
    }

    openEmail = () => {
        localStorage.setItem("lead_stage", this.state.stage)
        this.props.dispatch(openEmailModel());
    }

    openPreview = () => {
        this.props.dispatch(openPreviewModel());
    }

    openSuccess = () => {
        this.props.dispatch(openSuccessModel());
    }

    handleChangePage = (event, value) => {
        const sfid = localStorage.getItem('sfid');
        let data = `page=${value}&stage=${this.state.stage}&limit=${this.state.limit}`;
        this.setState({ page: value });
        this.props.dispatch(getLeads(data, sfid)).then(res => {
            if (res.status == "success") {
                const accordian1 = document.getElementsByClassName("cc1")
                const arrowup = document.getElementsByClassName("fa-angle-up")
                for (let i = 0; i < accordian1.length; i++) {
                    accordian1[i].style.display = "none"
                }
                for (let index = 0; index < arrowup.length; index++) {
                    const element = arrowup[index];
                    setTimeout(() => {
                        element.classList.remove("fa-angle-up")
                        element.classList.add("fa-angle-down")
                    }, 1000);

                }

            }
        });
    }



    handleChangelimitOfPage = (event) => {
        let perRowData = event.target.value;
        const sfid = localStorage.getItem('sfid');
        // let data =  `page=${perRowData}`;
        let data = `page=${this.state.page}&stage=${this.state.stage}&limit=${perRowData}`;
        this.setState({ limit: perRowData });
        this.props.dispatch(getLeads(data, sfid));
    }

    getSheetData = async (data, header) => {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        sheetData.unshift(header);
        return sheetData;
    }

    generatePDF = () => {
        const { sfid } = this.props
        let stage = this.state.stage;
        let obj = `stage=${stage}`;
        this.props.dispatch(merchantLeadDownload(obj, sfid)).then((response) => {
            if (response.responseCode !== undefined && response.responseCode === 400) {
            } else {
                const getData = response;
                this.generatePDFData(getData);
            }
        });
    }

    generateCsv = () => {
        const { sfid } = this.props
        let stage = this.state.stage;
        let obj = `stage=${stage}`;
        this.props.dispatch(merchantLeadDownload(obj, sfid)).then((response) => {
            if (response.responseCode !== undefined && response.responseCode === 400) {
            } else {
                const getData = response;
                this.saveAsExcel(getData);
            }
        });
    }

    generatePDFData = async getData => {
        const doc = new jsPDF();
        const tableColumn = ["#", "Application ID", "Application Details", "Product Name", "Order Value", "Status"];
        const tableRows = [];
        await Promise.all(getData.map((item, index) => {
            const leadData = [
                index + 1,
                item.transaction_application_id__c ? item.transaction_application_id__c : '-',
                item.name ? item.name : '-',
                item.product_name ? item.product_name : '-',
                item.product_mrp ? item.product_mrp : '-',
                item.status ? item.status : '-',
            ];
            tableRows.push(leadData);
        }));
        doc.autoTable(tableColumn, tableRows, { startY: 20 });
        const date = Date().split(" ");
        const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
        doc.text("Closed tickets within the last one month.", 14, 15);
        doc.save(`report_${dateStr}.pdf`);
    }

    saveAsExcel = async (getData) => {
        var data = [];
        await Promise.all(getData.map(async (item, index) => {
            data.push({
                s_no: index + 1,
                sfid: item.transaction_application_id__c ? item.transaction_application_id__c : '-',
                name: item.name ? item.name : '-',
                product_name: item.product_name ? item.product_name : '-',
                product_mrp: item.product_mrp ? item.product_mrp : '-',
                status: item.status ? item.status : '-',
            })
        }));
        let header = ["#", "Application ID", "Application Details", "Product Name", "Order Value", "Status"];
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = await this.getSheetData(data, header);
            const totalColumns = sheetData[0].length;
            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "report.xlsx");
            });
        });
    }

    closeFilterMainModal = () => {
        this.setState({
            showFilterModal: false
        })


    }

    closeApprovedcenterPopUp = () => {
        this.setState({ showApprovedCenteredPopUp: false, BatchStartDate: "", BatchLastDate: "", isBatchLastdate: false, isBatchStartdate: false })
    }

    closeonholdcenterPopUp = () => {
        this.setState({ showonholdCenteredPopUp: false, reasonOnhold: "" })
    }

    closerejectedcenterPopUp = () => {
        this.setState({ showrejectedCenteredPopUp: false, rejectedReason: "" })
    }

    SelectedModal = (ModalType, Cart_id) => {
        //e.stopPropagation();
        if(this.state.activeDropDown){
            this.setState({activeDropDown:null})
        }
        this.setState({ selectedId: Cart_id })
        if (ModalType == 'approve') {
            this.setState({
                showApprovedCenteredPopUp: true
            })
            this.props.dispatch(clearApprovalOnholdRejectErr('approve'))
        }
        if (ModalType == 'hold') {
            this.setState({
                showonholdCenteredPopUp: true
            })
            this.props.dispatch(clearApprovalOnholdRejectErr('hold'))
        }
        if (ModalType == 'reject') {
            this.setState({
                showrejectedCenteredPopUp: true
            })
            this.props.dispatch(clearApprovalOnholdRejectErr('reject'))
        }
    }

    handleClearfilter = () => {
        this.setState({ clearfilterValue: !this.state.clearfilterValue })
    }

    handleReqbtn = (e, status, id) => {
        e.stopPropagation()
        const obj = {
            id: id,
            //paymentStatus: status,
            paymentStatus: 'Fee Payment Pending',

            merchant_id: this.props.sfid

        }
        this.props.dispatch(getPaymentStatus(obj)).then(response => {
            if (response.status == 401) {
                this.props.dispatch(logOut());
                history.push('/login');
            }
        })

        this.setState({
            whiteRequestbtn: true,
            btnClickedArr: [...new Set([...this.state.btnClickedArr, id])]
        })

    }
    checkArrbtn(arr,) {

    }
    handleChangePageNew = (event, newPage) => {
        const { sfid } = this.props
        let { limit, stage } = this.state;
        // if (this.props.globalseraching) {
        //     this.setState({ serachPage: newPage })
        //     return;
        // }
        let objData = `page=${newPage + 1}&stage=${stage}&limit=${limit}`;
        this.getLeadsData("", newPage + 1,);
        this.setState({ page: newPage, showValue: "" })
    };
    handleChangeRowsPerPage = (event) => {
        // this.setState({rowsPerPage:parseInt(event.target.value, 10),page:0})
        // if (this.props.globalseraching) {
        //     this.setState({ searchLimit: parseInt(event.target.value, 10), serachPage: 0, rowsPerPage: parseInt(event.target.value, 10) });
        //     return;
        // }
        const { sfid } = this.props
        let { limit, stage } = this.state;
        let objData = `page=${1}&stage=${stage}&limit=${parseInt(event.target.value, 10)}`;
        this.getLeadsData("", 1, parseInt(event.target.value, 10));
        // this.props.dispatch(getLeads(objData, sfid));
        this.setState({ limit: parseInt(event.target.value, 10), page: 0, rowsPerPage: parseInt(event.target.value, 10), showValue: "" });
    };

    //   handleStartBatchdate=(startdate)=>{
    //     this.setState({
    //         BatchStartDate:startdate
    //     })
    //   }
    //   handleLastBatchdate=(lastdate)=>{
    //     this.setState({
    //         BatchLastDate:lastdate
    //     })
    //   }


    handleStartBatchdate = (startdate) => {
        if (moment(startdate.$d).format('YYYY-MM-DD') !== 'Invalid date') {
            this.setState({
                BatchStartDate: moment(startdate.$d).format('YYYY-MM-DD'),
                invalidStartDate: false,
                isBatchStartdate: !this.state.isBatchStartdate,
                BatchLastDate: null,
                invalidEndDate: true,

            })
        }
        else {
            this.setState({
                invalidStartDate: true
            })

        }
    }

    handleLastBatchdate = (lastdate) => {
        if (moment(lastdate.$d).format('YYYY-MM-DD') !== 'Invalid date') {
            this.setState({
                BatchLastDate: moment(lastdate.$d).format('YYYY-MM-DD'),
                isBatchLastdate: !this.state.isBatchLastdate,
                invalidEndDate: false,
                //isBatchStartdate:false
            })
        }
        else {
            this.setState({
                invalidEndDate: true
            })
        }
    }

    handleApproved = () => {
        const { BatchStartDate, BatchLastDate, selectedId } = this.state;
        let payload = {
            "id": selectedId,
            "last_enroiment_date": BatchLastDate,
            "batch_start_date": BatchStartDate,
            "status": 3,
            "merchant_id": this.props.sfid

        }
        this.props.dispatch(getApproveLeadStatusData(payload)).then((response) => {
            if (response.status !== "success") {
                if (response.status == 401) {
                    this.props.dispatch(logOut());
                    history.push('/login');
                }
            } else {
                this.setState({ showApprovedCenteredPopUp: false, BatchStartDate: "", BatchLastDate: "" })
                this.getLeadsData();
            }
        });
    }

    handleOnHold = () => {
        const { reasonOnhold, selectedId } = this.state;
        let payload = {
            "id": selectedId,
            "reasone": reasonOnhold,
            "status": 9,
            "merchant_id": this.props.sfid
        }

        this.props.dispatch(getOnholdRejectLeadStatusData(payload)).then((response) => {
            if (response.status !== "success") {
                if (response.status == 401) {
                    this.props.dispatch(logOut());
                    history.push('/login');
                }
            } else {
                this.setState({ showonholdCenteredPopUp: false, reasonOnhold: "" })
                this.getLeadsData();
            }
        });
    }

    handleRejectLeads = () => {
        const { rejectedReason, selectedId } = this.state;
        let payload = {
            "id": selectedId,
            "reasone": rejectedReason,
            "status": 10,
            "merchant_id": this.props.sfid
        }
        this.props.dispatch(getOnholdRejectLeadStatusData(payload)).then((response) => {
            if (response.status !== "success") {
                if (response.status == 401) {
                    this.props.dispatch(logOut());
                    history.push('/login');
                }
            } else {
                this.setState({ showrejectedCenteredPopUp: false, rejectedReason: "" })
                this.getLeadsData();
            }
        });
    }

    sortBy = (givenArray, sortKey, order, value) => {
        const sortedData = tableSort(givenArray, sortKey, order);
        this.setState({ leadsData: sortedData })
        // if(order === 1){
        //   this.setState({showValue:"CourseFeesUp"})
        // }else{
        //   this.setState({showSortDown:true,showSortUp:false})
        // }
        this.setState({ showValue: value })
    }

    handleFilterValue = (obj) => {
        this.setState({ productFilter: obj, resetPage: !this.state.resetPage })
    }

    componentWillUnmount(){
        this.props.dispatch(closeLeadApplicationModel());
        this.props.dispatch(setLeadDetailsFailure())
      }

      setActiveDropDown=(givenCartId)=>{
        if(this.state.activeDropDown){
            this.setState({activeDropDown:null})
        }
        else{
            this.setState({activeDropDown:givenCartId})
        }
      }

    render() {
        const { user_id, leadsCount, show_searched, globalSearch, activeWizrTab, globalseraching, leadSkills, searchedKeyword, approveFailedMsg } = this.props;
        let { leads } = this.props;
        let { page, limit, leadDataState, rejectedReason, reasonOnhold, BatchStartDate, BatchLastDate, errorMsg, stage, leadsData, searchLimit, serachPage } = this.state;
        let { merchantLeadCount, merchantLeads } = leadSkills;
        //  let static_sfid= "001C30000073CLaIAM"
        //  let static_id="8193"
        // let static_opp_id="006C3000004Lb8jIAC"
        // let static_stage_title="Application Pending"
        let count;
        let { leadCount } = leads;
        if (!user_id) {
            return <Redirect to="/login" />
        }
        // const totalPages = Math.ceil(leadCount / Number(this.state.limit));
        // let searched={}
        // leads=show_searched?globalSearch:leads
        // if(show_searched){
        //     searched['proData']=leads;
        //     leads=searched            
        // }

        // let showPageination = false;
        let totalPages = 1;
        // if (globalseraching && globalSearch && globalSearch.length > 0) {
        //     showPageination = true;
        //     totalPages = globalSearch ? Math.ceil(globalSearch.length / Number(this.state.searchLimit)) : 1;
        //     count = globalSearch.length;
        // }
        if (leadsData && leadsData.length > 0) {
            // showPageination = true;
            // totalPages =  products.all_product_count ? Math.ceil(products.all_product_count / Number(this.state.limit)) : 1;
            let { RejectedLeads, OnholdLeads, ApprovedLeads, PendingLeads } = merchantLeadCount ? merchantLeadCount : {};
            count = stage === "Rejected" ? RejectedLeads : stage === "Onhold" ? OnholdLeads : stage === "Approved" ? ApprovedLeads : stage === "Pending" ? PendingLeads : ""
            totalPages = count ? Math.ceil(count / Number(this.state.limit)) : 1;
        }
        return (
            <>
                <Helmet>
                    <title>Eduvanz - Leads</title>
                </Helmet>
                {/* <FilterMain show={this.state.showFilterModal} close={this.closeFilterMainModal} handleClearfilter={this.handleClearfilter} >
                    {<UserProfilefilter clearfilterValue={this.state.clearfilterValue}/>}
                    {<ProductFilter/>}
                    {<UserProfilefilter clearfilterValue={this.state.clearfilterValue}/>}
                   
                </FilterMain> */}
                <LeadSkillFilter show={this.state.showFilterModal} close={this.closeFilterMainModal} stage={this.state.stage} clearfilterValue={this.state.clearfilterValue} handleFilterValue={this.handleFilterValue} />

                <div id="wrapper">
                    <Sidebar />
                    <div id="content-wrapper" className="d-flex flex-column ">
                        <div id="content">
                            {/* <Topbar
                                dispatch={this.props.dispatch}
                                title={"Leads"}
                                createLead={true}
                                stage={this.state.stage}
                            /> */}
                            {/* End of Topbar */}
                            {/* Begin Page Content */}
                            <TopBarWizrHelpNotification />

                            <TopWizrbar />
                            <div className="container-fluid">
                                <div className="row flex-lg-row flex-column-reverse align-items-center" >
                                    <div className="col-md-8 padding0">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a
                                                    className="nav-item nav-link active"
                                                    id="nav-application-tab"
                                                    data-toggle="tab"
                                                    href="#nav-pre-approval"
                                                    role="tab"
                                                    aria-controls="nav-pre-approval"
                                                    aria-selected="true"
                                                    onClick={() => this.handleTabChange('Pending')}
                                                >
                                                    New ({leadSkills?.merchantLeadCount?.PendingLeads ? leadSkills.merchantLeadCount.PendingLeads : 0})
                                                </a>
                                                <a
                                                    className="nav-item nav-link nav-post"
                                                    id="nav-documentation-tab"
                                                    data-toggle="tab"
                                                    href="#nav-post-approval"
                                                    role="tab"
                                                    aria-controls="nav-post-approval"
                                                    aria-selected="false"
                                                    onClick={() => this.handleTabChange('Approved')}
                                                >
                                                    Approved ({leadSkills?.merchantLeadCount?.ApprovedLeads ? leadSkills.merchantLeadCount.ApprovedLeads : 0})
                                                </a>
                                                <a
                                                    className="nav-item nav-link "
                                                    id="nav-onhold-docs"
                                                    data-toggle="tab"
                                                    href="#nav-onhold-tab"
                                                    role="tab"
                                                    aria-controls="#nav-onhold-tab"
                                                    aria-selected="true"
                                                    onClick={() => this.handleTabChange('Onhold')}
                                                >
                                                    Onhold ({leadSkills?.merchantLeadCount?.OnholdLeads ? leadSkills.merchantLeadCount.OnholdLeads : 0})
                                                </a>
                                                <a
                                                    className="nav-item nav-link "
                                                    id="nav-rejected-docs"
                                                    data-toggle="tab"
                                                    href="#nav-rejected-tab"
                                                    role="tab"
                                                    aria-controls="#nav-rejected-tab"
                                                    aria-selected="true"
                                                    onClick={() => this.handleTabChange("Rejected")}
                                                >
                                                    Rejected ({leadSkills?.merchantLeadCount?.RejectedLeads ? leadSkills.merchantLeadCount.RejectedLeads : 0})
                                                </a>
                                                {/* <a
                                                    className="nav-item nav-link nav-wislist"
                                                    id="nav-documentation-tab"
                                                    data-toggle="tab"
                                                    href="#nav-whitelisting-request"
                                                    role="tab"
                                                    aria-controls="nav-whitelisting-request"
                                                    aria-selected="false"
                                                    onClick={() => this.handleTabChange('Whitelisting Request')}
                                                >
                                                    Whitelisting Request
                                                </a> */}
                                                {/* <a
                                                    className="nav-item nav-link nav_addicon">
                                                    {" "}
                                                    <i className="fas fa-plus" onClick={this.openFilter} style={{cursor:"pointer"}}/>{" "}
                                                </a> */}
                                            </div>
                                        </nav>
                                    </div>
                                    <div className="col-md-4 padding0">
                                        <ul className="list-group list-group-horizontal align-items-center justify-content-end">
                                            <li className="list-group-item">
                                                {/* <span className="nav_icons nav_sorting" onClick={this.openBulkModel} >
                                                    <img src="images/sorting.svg" alt="Sort" />
                                                </span> */}

                                            </li>
                                            <diV className="filterSearch">
                                                <Topbar
                                                    dispatch={this.props.dispatch}
                                                    title={"Leads"}
                                                    Type={"Skills"}
                                                    createLead={true}
                                                    stage={this.state.stage}
                                                    productFilter={this.state.productFilter}
                                                    handleFilterValue={this.handleFilterValue}
                                                />
                                                <li className="list-group-item pl-pr0"
                                                    onClick={() => this.setState(
                                                        {
                                                            showFilterModal: !this.state.showFilterModal
                                                        }
                                                    )}
                                                >

                                                    <button className="nav_icons nav_filter filtericon-new-btn" >
                                                        <img src="img/filterIcon.svg" className="mr-1" alt="Filter" />Filters
                                                    </button>

                                                </li>
                                            </diV>
                                            {/* <li className="list-group-item" id="enableDownloads">
                                                <div className="dropdown">
                                                    <button className="" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="nav_icons nav_download">
                                                            <img src="img/icon_Download.svg" alt="Download" />
                                                        </span>
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-leads shadow" aria-labelledby="dropdownMenuButton">
                                                        <div className="d-flex">
                                                            <button type="button" onClick={this.generateCsv} className="doc-dowlload-btn">
                                                                <img src="images/icons/xls.png" alt="Download" className="img-fluid" />
                                                            </button>
                                                            <button type="button" onClick={this.generatePDF} className="doc-dowlload-btn">
                                                                <img src="images/icons/pdf.png" alt="Download" className="img-fluid" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </li> */}


                                            {/* <li className="list-group-item" onClick={this.openEmail}> */}
                                            {/* <span className="nav_icons nav_download" style={{ "fontSize": "20px" }}> */}
                                            {/* <img src="images/icons/icon-email.png" alt="Email" /> */}
                                            {/* <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                                </span> */}
                                            {/* </li> */}
                                        </ul>
                                    </div>
                                </div>

                                <div className="sorting_values">
                                    <ul>
                                        <li>
                                            <input
                                                type="checkbox"
                                                defaultValue="recentlyUpdated"
                                                name="radio"
                                                id="radio1"
                                            />
                                            <label htmlFor="radio1">Recently Updated</label>
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                defaultValue="loanAmountHightoLow"
                                                name="radio"
                                                id="radio2"
                                            />
                                            <label htmlFor="radio2">Loan Amount: High to Low</label>
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                defaultValue="loanAmountLowtoHigh"
                                                name="radio"
                                                id="radio3"
                                            />
                                            <label htmlFor="radio3">Loan Amount: Low to High</label>
                                        </li>
                                    </ul>
                                </div>


                                <div className="row">
                                    <div className="col-md-12 padding0">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="tab-content" id="nav-tabContent">
                                                    <div
                                                        className="tab-pane fade show active"
                                                        id="nav-pre-approval"
                                                        role="tabpanel"
                                                        aria-labelledby="nav-application-tab"
                                                    >
                                                        <div className="table-responsive">
                                                            {globalseraching && <div className='search-result-text'>{`${count ? count : 0} Results found for "${searchedKeyword}"`}</div>}

                                                            <table
                                                                className="table tableStyle leadsSkillTable"
                                                                // id="dataTable"
                                                                width="100%"
                                                                cellSpacing={0}
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th className="appHead-headinghash">#</th>
                                                                        <th>
                                                                            {/* Application ID  */}
                                                                            <div className="d-flex align-items-center appHead-heading ">Application ID
                                                                                <div className="d-flex flex-column ml-1">
                                                                                    <button className={this.state.showValue === "NewApplicationUp" ? "up active" : "up"} onClick={() => this.sortBy(leadsData, 'cart_application_id', 1, "NewApplicationUp")}></button>
                                                                                    <button className={this.state.showValue === "NewApplicationDown" ? "down active" : "down"} onClick={() => this.sortBy(leadsData, 'cart_application_id', -1, "NewApplicationDown")}></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th className="appHead-heading">
                                                                            Course Name
                                                                        </th>
                                                                        <th>
                                                                            <div className="d-flex align-items-center appHead-heading">User Profile
                                                                                <div className="d-flex flex-column">

                                                                                    <button className={this.state.showValue === "NewUserProfileUp" ? "up active" : "up"} onClick={() => this.sortBy(leadsData, 'profession', 1)}></button>
                                                                                    <button className={this.state.showValue === "NewUserProfileDown" ? "down active" : "down"} onClick={() => this.sortBy(leadsData, 'profession', -1)}></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th className="appHead-heading">
                                                                            DOB
                                                                        </th>
                                                                        {activeWizrTab === 'WIZR Skills' && (<th className="appHead-heading">Gender</th>)}
                                                                        {activeWizrTab === 'WIZR Skills' && (<th className="appHead-heading">Qualification</th>)}
                                                                        <th>
                                                                            {/* Order Value */}
                                                                            <div className="d-flex align-items-center appHead-heading"> Work Exp.
                                                                                <div className="d-flex flex-column">
                                                                                    <button className={this.state.showValue === "NewWorkExpUp" ? "up active" : "up"} onClick={() => this.sortBy(leadsData, 'work_experience', 1)}></button>
                                                                                    <button className={this.state.showValue === "NewWorkExpDown" ? "down active" : "down"} onClick={() => this.sortBy(leadsData, 'work_experience', -1)}></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th className="appHead-heading">Actionable</th>
                                                                        <th className="appHead-heading">More Info</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    <>
                                                                        {leadsData?.length > 0 ?

                                                                            leadsData.map((item, index) => (

                                                                                <tr className="shown trhover cursor-point" onClick={(e) => this.openLeadApplication(item)}>
                                                                                    <td className="appIDnum text-center">{(page * limit) + (index + 1)}</td>
                                                                                    <td>
                                                                                        <div className="d-flex flex-column" data-toggle="modal"
                                                                                            data-target="#myModa20" >
                                                                                            <b className="appIDLeads">{item.cart_application_id ? item.cart_application_id : "-"}</b>
                                                                                            <span className="mt-1 date appIDDates">{item.created_at ? moment(item.created_at.split('T')[0]).format('ll') : '-'}</span>
                                                                                        </div></td>
                                                                                    <td><p className="d-block appIDCourseName appTextline">{item.product_name ? item.product_name : '-'}</p></td>
                                                                                    <td className="appIDProfile">{item.profession ? item.profession : "-"}</td>
                                                                                    <td className="appIDProfile">{item.dob ? moment(item.dob).format('DD/MM/YYYY') : "-"}</td>
                                                                                    <td className="appIDProfile">{item.gender ? item.gender : ""}</td>
                                                                                    <td className="appIDProfile">{item.qualification ? item.qualification : '-'}</td>
                                                                                    <td className="appIDProfile">{item.work_experience ? item.work_experience : "-"}</td>
                                                                                    <td  onClick={(e)=>e.stopPropagation()}>

                                                                                        <div class="dropdown" key={{index}} >
                                                                                            {/* <button class="btn  dropdown-toggle btn-sm dropdownData" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                                                                            <button role="button" type="button" className=" d-flex btn dropdown dropdownData" data-toggle="dropdown"  onClick={()=>this.setActiveDropDown(item.cart_id)}>
                                                                                                Select 
                                                                                                    <span><i className="fa fa-angle-down paddingLeft10"></i></span>
                                                                                            </button>
                                                                                            <div class={`dropdown-menu dropdown-menu-leads ${this.state.activeDropDown == item.cart_id?'show':''}`} aria-labelledby="dropdownMenuButton" id={item.cart_id}>

                                                                                                <a class="dropdown-item" onClick={() => this.SelectedModal('approve', item.cart_id)}  >Approve</a>
                                                                                                 <a class="dropdown-item" onClick={() => this.SelectedModal('hold', item.cart_id)} >On hold</a>
                                                                                                <a class="dropdown-item" onClick={() => this.SelectedModal('reject', item.cart_id)}>Reject</a>

                                                                                            </div>
                                                                                        </div>

                                                                                    </td>

                                                                                    <td><i className="fa fa-angle-right float-right pt-2  cursor-point" style={{ fontSize: "1.464vw" }} data-toggle="modal" data-target="#myModaxl8"   ></i></td>


                                                                                </tr>

                                                                            ))


                                                                            :
                                                                            <td colSpan="10"><NoRecordsFound message={globalseraching? searchedKeyword :'No Data to show '} show_searched={globalseraching} /></td>
                                                                        }

                                                                    </>
                                                                </tbody>
                                                            </table>
                                                            <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-4">
                                                                {/* <div className="d-flex align-items-center row_per_page mr-lg-5 mr-4">
                                    <p>Row per page</p>
                                    <select page={this.state.page} onChange={this.handleChangelimitOfPage}>
                                      <option value={10}>10</option>
                                      <option value={20}>20</option>
                                      <option value={30}>30</option>
                                    </select>
                                  </div> */}
                                                                <div className="d-flex align-items-center pages">
                                                                    {/* <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} /> */}
                                                                    {count > 10 && <TablePagination
                                                                        component="div"
                                                                        count={count}
                                                                        page={this.state.page}
                                                                        rowsPerPage={this.state.limit}
                                                                        onPageChange={this.handleChangePageNew}
                                                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                                                        //   className="leadtablepagination"
                                                                        // labelDisplayedRows={({ page }) => {
                                                                        //   return `Page: ${this.state.page} of 200 `;
                                                                        // }}
                                                                        // labelDisplayedRows={<span>Rows:</span>}
                                                                        labelDisplayedRows={({ page }) => {

                                                                            return `Page: ${this.state.page + 1} of ${totalPages} `;

                                                                        }}
                                                                    />}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {/* <div style={{ display: 'block', padding: 30, marginLeft: "76%" }}>
                                                            <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} />
                                                        </div> */}
                                                    </div>

                                                    {/* post-approval */}
                                                    <div
                                                        className="tab-pane fade"
                                                        id="nav-post-approval"
                                                        role="tabpanel"
                                                        aria-labelledby="nav-documentation-tab"
                                                    >
                                                        <div className="table-responsive">
                                                            {globalseraching && <div className='search-result-text'>{`${count ? count : 0} Results found for "${searchedKeyword}"`}</div>}

                                                            <table
                                                                className="table tableStyle leadsSkillTable"
                                                                // id="dataTable"
                                                                width="100%"
                                                                cellSpacing={0}
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th className="appHead-headinghash">#</th>
                                                                        <th>
                                                                            {/* Application ID  */}
                                                                            <div className="d-flex align-items-center appHead-heading">Application ID
                                                                                <div className="d-flex flex-column ml-1">
                                                                                    <button className={this.state.showValue === "ApprovedApplicationUp" ? "up active" : "up"} onClick={() => this.sortBy(leadsData, 'cart_application_id', 1, "ApprovedApplicationUp")}></button>
                                                                                    <button className={this.state.showValue === "ApprovedApplicationDown" ? "down active" : "down"} onClick={() => this.sortBy(leadsData, 'cart_application_id', -1, "ApprovedApplicationDown")}></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th className="appHead-heading">
                                                                            Course Name
                                                                        </th>
                                                                        <th>
                                                                            <div className="d-flex align-items-center appHead-heading">Course Fees
                                                                                <div className="d-flex flex-column">
                                                                                    <button className={this.state.showValue === "CourseFeesUp" ? "up active" : "up"} onClick={() => this.sortBy(leadsData, 'product_fees', 1, "CourseFeesUp")}></button>
                                                                                    <button className={this.state.showValue === "CourseFeesDown" ? "down active" : "down"} onClick={() => this.sortBy(leadsData, 'product_fees', -1, "CourseFeesDown")}></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div className="d-flex align-items-center appHead-heading">Batch Date 
                                                                                <div className="d-flex flex-column">
                                                                                    <button className={this.state.showValue === "CourseBatchUp" ? "up active" : "up"} onClick={() => this.sortBy(leadsData, 'batch_start_date', 1, "CourseBatchUp")}></button>
                                                                                    <button className={this.state.showValue === "CourseBatchDown" ? "down active" : "down"} onClick={() => this.sortBy(leadsData, 'batch_start_date', -1, "CourseBatchDown")}></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th className="appHead-heading">Status</th>
                                                                        {/* current hide this col */}
                                                                        {/* <th className="appHead-heading">Payment Request</th> */}

                                                                        <th className="appHead-heading">More Info</th>
                                                                    </tr>
                                                                </thead>

                                                                {/**Approved */}
                                                                <tbody>
                                                                    <>
                                                                        {leadsData?.length > 0 ?

                                                                            leadsData.map((item, index) => (

                                                                                <tr className="shown trhover cursor-point" onClick={() => this.openLeadApplication(item)}>
                                                                                    <td className="appIDnum text-center">{(page * limit) + (index + 1)}</td>
                                                                                    <td>
                                                                                        <div className="d-flex flex-column" data-toggle="modal"
                                                                                            data-target="#myModa20" >
                                                                                            <b className="appIDLeads"> {item.cart_application_id}</b>
                                                                                            <span className="mt-1 date appIDDates">{item.created_at ? moment(item.created_at.split('T')[0]).format('ll') : "-"}</span>
                                                                                        </div></td>
                                                                                    <td><p className="d-block appIDCourseName appTextline">{item.product_name}</p></td>
                                                                                    <td className="appIDProfile"> {item.product_fees ? `₹ ${item.product_fees.toLocaleString('en-IN')}` : '-'}</td>
                                                                                    <td className="appIDProfile">{item.batch_start_date ? moment(item.batch_start_date).format('DD/MM/YYYY') : "-"}</td>
                                                                                    <td className="appIDProfile">{item.status}</td>
                                                                                    {/* <td> */}
                                                                                    {/* <button className={`btn-sm  ${this.state.whiteRequestbtn?'whitebtnRequest':'applicationrequestbtn'}`} onClick={()=>{this.setState({whiteRequestbtn:!this.state.whiteRequestbtn});console.log('hereo',this.state.whiteRequestbtn) }} >{this.state.whiteRequestbtn?'Requested':'Request'}</button> */}
                                                                                    {/* {(item.status == 'Block Amount Requested' || item.status == 'Fee Payment Requested') && (<button className='whitebtnRequest'  >Requested</button>)}
                                                                                            {(item.status == 'Block Amount Pending' || item.status == 'Fee Payment Pending') && (<button className={` ${this.state.btnClickedArr.includes(item.cart_application_id) ? this.state.whiteRequestbtn ? 'whitebtnRequest' : '`btn-sm applicationrequestbtn' : 'btn-sm applicationrequestbtn'}`} onClick={(e) => this.handleReqbtn(e, item.status, item.cart_id)} >{this.state.btnClickedArr.includes(item.cart_application_id) ? this.state.whiteRequestbtn ? 'Requested' : 'Request' : 'Request'}</button>)}
                                                                                            {item.status == 'Fee Payment Done' && (<button className='Na-style'>NA</button>)} */}

                                                                                    {/* {(item.status == 'Fee Payment Requested') && (<button className='whitebtnRequest'  >Requested</button>)}
                                                                                            {(item.status == 'Fee Payment Done' || item.status == 'Block Amount Pending' ) && (<button className='Na-style'  >NA</button>)}
                                                                                            {(item.status == 'Fee Payment Pending') && (<button className='btn-sm applicationrequestbtn' onClick={this.handleReqbtn} >Request</button>)} */}
                                                                                    {/* </td>  */}


                                                                                    <td><i className="fa fa-angle-right float-right pt-2  cursor-point" style={{ fontSize: "1.464vw" }} data-toggle="modal" data-target="#myModaxl8"   ></i></td>


                                                                                </tr>


                                                                            ))


                                                                            :
                                                                            <td colSpan="10"><NoRecordsFound message={globalseraching? searchedKeyword :'No Data to show '} show_searched={globalseraching} /></td>
                                                                        }

                                                                    </>
                                                                </tbody>
                                                            </table>
                                                            <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-4">
                                                                {/* <div className="d-flex align-items-center row_per_page mr-lg-5 mr-4">
                                                                <p>Row per page </p>
                                                                <select page={this.state.page} onChange={this.handleChangelimitOfPage}>
                                                                    <option value={10}>10</option>
                                                                    <option value={20}>20</option>
                                                                    <option value={30}>30</option>
                                                                </select>
                                                            </div> */}
                                                                <div className="d-flex align-items-center pages">
                                                                    {/* <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} /> */}
                                                                    {count > 10 && <TablePagination
                                                                        component="div"
                                                                        count={count}
                                                                        page={this.state.page}
                                                                        rowsPerPage={this.state.limit}
                                                                        onPageChange={this.handleChangePageNew}
                                                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                                                        //   className="leadtablepagination"
                                                                        // labelDisplayedRows={({ page }) => {
                                                                        //   return `Page: ${this.state.page} of 200 `;
                                                                        // }}
                                                                        // labelDisplayedRows={<span>Rows:</span>}
                                                                        labelDisplayedRows={({ page }) => {

                                                                            return `Page: ${this.state.page + 1} of ${totalPages} `;

                                                                        }}
                                                                    />
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {/* <div style={{ display: 'block', padding: 30, marginLeft: "76%" }}>
                                                            <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} />
                                                        </div> */}
                                                    </div>
                                                    {/* end */}

                                                    {/* whitelisting-request */}
                                                    <div
                                                        className="tab-pane fade"
                                                        id="nav-onhold-tab"
                                                        role="tabpanel"
                                                        aria-labelledby="nav-onhold-docs"
                                                    >

                                                        <div className="table-responsive">
                                                            {globalseraching && <div className='search-result-text'>{`${count ? count : 0} Results found for "${searchedKeyword}"`}</div>}

                                                            <table
                                                                className="table tableStyle leadsSkillTable"
                                                                // id="dataTable"
                                                                width="100%"
                                                                cellSpacing={0}

                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th className="appHead-headinghash">#</th>
                                                                        <th>
                                                                            {/* Application ID  */}
                                                                            <div className="d-flex align-items-center appHead-heading">Application ID
                                                                                <div className="d-flex flex-column ml-1">
                                                                                    <button className={this.state.showValue === "OnholdApplicationUp" ? "up active" : "up"} onClick={() => this.sortBy(leadsData, 'cart_application_id', 1, "OnholdApplicationUp")}></button>
                                                                                    <button className={this.state.showValue === "OnholdApplicationDown" ? "down active" : "down"} onClick={() => this.sortBy(leadsData, 'cart_application_id', -1, "OnholdApplicationDown")}></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th className="appHead-heading">
                                                                            Course Name
                                                                        </th>
                                                                        <th>
                                                                            <div className="d-flex align-items-center appHead-heading">User Profile
                                                                                <div className="d-flex flex-column">
                                                                                    <button className={this.state.showValue === "OnholdUserProfileUp" ? "up active" : "up"} onClick={() => this.sortBy(leadsData, 'profession', 1)}></button>
                                                                                    <button className={this.state.showValue === "OnholdUserProfileDown" ? "down active" : "down"} onClick={() => this.sortBy(leadsData, 'profession', -1)}></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th className="appHead-heading">
                                                                            DOB
                                                                        </th>
                                                                        <th className="appHead-heading">Gender</th>
                                                                        <th className="appHead-heading">Qualification</th>
                                                                        <th>
                                                                            {/* Order Value */}
                                                                            <div className="d-flex align-items-center appHead-heading"> Work Exp.
                                                                                <div className="d-flex flex-column">
                                                                                    <button className={this.state.showValue === "OnholdWorkExpUp" ? "up active" : "up"} onClick={() => this.sortBy(leadsData, 'work_experience', 1)}></button>
                                                                                    <button className={this.state.showValue === "OnholdWorkExpDown" ? "down active" : "down"} onClick={() => this.sortBy(leadsData, 'work_experience', -1)}></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th className="appHead-heading">Actionable</th>
                                                                        <th className="appHead-heading">More Info</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <>
                                                                        {leadsData?.length > 0 ?

                                                                            leadsData.map((item, index) => (

                                                                                <tr className="shown trhover cursor-point" onClick={() => this.openLeadApplication(item)}>
                                                                                    <td className="appIDnum text-center">{(page * limit) + (index + 1)}</td>
                                                                                    <td>
                                                                                        <div className="d-flex flex-column" data-toggle="modal"
                                                                                            data-target="#myModa20" >
                                                                                            <b className="appIDLeads">{item.cart_application_id}</b>
                                                                                            <span className="mt-1 date appIDDates">{item.created_at ? moment(item.created_at.split('T')[0]).format('ll') : "-"}</span>
                                                                                        </div></td>
                                                                                    <td><p className="d-block appIDCourseName appTextline">{item.product_name}</p></td>

                                                                                    {/* <td className="appIDProfile">1,20,000</td>
                                                                        <td className="appIDProfile">{item.dob? moment(item.dob).format('DD/MM/YYYY'): "-"}</td> */}

                                                                                    <td className="appIDProfile">{item.profession}</td>

                                                                                    <td className="appIDProfile">{item.dob ? moment(item.dob).format('DD/MM/YYYY') : "-"}</td>
                                                                                    <td className="appIDProfile">{item.gender}</td>
                                                                                    <td className="appIDProfile">{item?.qualification ? item.qualification : "-"}</td>
                                                                                    <td className="appIDProfile">{item.work_experience ? item.work_experience : "-"}</td>

                                                                                    {/* <td className="appIDProfile">{item.status}</td> */}
                                                                                    {/* <td>
                                                                          <button className={` ${this.state.whiteRequestbtn?'whitebtnRequest':'`btn-sm applicationrequestbtn'}`} onClick={this.handleReqbtn} >{this.state.whiteRequestbtn?'Requested':'Request'}</button> 
                                                                        </td> */}
                                                                                    <td onClick={e => e.stopPropagation()}>

                                                                                        <div class="dropdown">
                                                                                            {/* <button class="btn  dropdown-toggle btn-sm dropdownData" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                                                                            <button role="button" type="button" className=" d-flex btn dropdown dropdownData" data-toggle="dropdown" onClick={()=>this.setActiveDropDown(item.cart_id)}>
                                                                                                Select
                                                                                                    <span><i className="fa fa-angle-down paddingLeft10"></i></span>
                                                                                            </button>
                                                                                            <div class={`dropdown-menu dropdown-menu-leads ${this.state.activeDropDown == item.cart_id?'show':''}`} aria-labelledby="dropdownMenuButton" id={item.cart_id}>
                                                                                                <a class="dropdown-item" onClick={() => this.SelectedModal('approve', item.cart_id)}  >Approve</a>
                                                                                                {/* <a class="dropdown-item" onClick={()=>this.SelectedModal('hold')} >onHold</a> */}
                                                                                                <a class="dropdown-item" onClick={() => this.SelectedModal('reject', item.cart_id)}>Reject</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>

                                                                                    <td><i className="fa fa-angle-right float-right pt-2  cursor-point" style={{ fontSize: "1.464vw" }} data-toggle="modal" data-target="#myModaxl8"   ></i></td>
                                                                                </tr>

                                                                            ))
                                                                            :
                                                                            <td colSpan="10"><NoRecordsFound message={globalseraching? searchedKeyword :'No Data to show '} show_searched={globalseraching} /></td>
                                                                        }
                                                                    </>
                                                                </tbody>
                                                            </table>
                                                            <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-4">

                                                                {/* <div className="d-flex align-items-center row_per_page mr-lg-5 mr-4">
                                                                <p>Row per page </p>
                                                                <select page={this.state.page} onChange={this.handleChangelimitOfPage}>
                                                                    <option value={10}>10</option>
                                                                    <option value={20}>20</option>
                                                                    <option value={30}>30</option>
                                                                </select>
                                                            </div>  */}

                                                                <div className="d-flex align-items-center pages">
                                                                    {/* <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} /> */}
                                                                    {count > 10 && <TablePagination
                                                                        component="div"
                                                                        count={count}
                                                                        page={this.state.page}
                                                                        rowsPerPage={this.state.limit}
                                                                        onPageChange={this.handleChangePageNew}
                                                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                                                        //   className="leadtablepagination"
                                                                        // labelDisplayedRows={({ page }) => {
                                                                        //   return `Page: ${this.state.page} of 200 `;
                                                                        // }}
                                                                        // labelDisplayedRows={<span>Rows:</span>}
                                                                        labelDisplayedRows={({ page }) => {

                                                                            return `Page: ${this.state.page + 1} of ${totalPages} `;

                                                                        }}
                                                                    />}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {/* <div style={{ display: 'block', padding: 30, marginLeft: "76%" }}>
                                                            <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} />
                                                        </div> */}

                                                    </div>



                                                    <div
                                                        className="tab-pane fade"
                                                        id="nav-rejected-tab"
                                                        role="tabpanel"
                                                        aria-labelledby="nav-rejected-docs"
                                                    >

                                                        <div className="table-responsive">
                                                            {globalseraching && <div className='search-result-text'>{`${count ? count : 0} Results found for "${searchedKeyword}"`}</div>}

                                                            <table
                                                                className="table tableStyle leadsSkillTable"
                                                                // id="dataTable"
                                                                width="100%"
                                                                cellSpacing={0}
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th className="appHead-headinghash">#</th>
                                                                        <th>
                                                                            {/* Application ID  */}
                                                                            <div className="d-flex align-items-center appHead-heading">Application ID
                                                                                <div className="d-flex flex-column ml-1">
                                                                                    <button className={this.state.showValue === "RejectedApplicationUp" ? "up active" : "up"} onClick={() => this.sortBy(leadsData, 'cart_application_id', 1, "RejectedApplicationUp")}></button>
                                                                                    <button className={this.state.showValue === "RejectedApplicationDown" ? "down active" : "down"} onClick={() => this.sortBy(leadsData, 'cart_application_id', -1, "RejectedApplicationDown")}></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th className="appHead-heading">
                                                                            Course Name
                                                                        </th>
                                                                        <th>
                                                                            <div className="d-flex align-items-center appHead-heading">User Profile
                                                                                <div className="d-flex flex-column">
                                                                                    <button className={this.state.showValue === "RejectedUserProfileUp" ? "up active" : "up"} onClick={() => this.sortBy(leadsData, 'profession', 1)}></button>
                                                                                    <button className={this.state.showValue === "RejectedUserProfileDown" ? "down active" : "down"} onClick={() => this.sortBy(leadsData, 'profession', -1)}></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th className="appHead-heading">
                                                                            DOB
                                                                        </th>
                                                                        <th className="appHead-heading">Gender</th>
                                                                        <th className="appHead-heading">Qualification</th>
                                                                        <th>
                                                                            {/* Order Value */}
                                                                            <div className="d-flex align-items-center appHead-heading"> Work Exp.
                                                                                <div className="d-flex flex-column">
                                                                                    <button className={this.state.showValue === "RejectedWorkExpUp" ? "up active" : "up"} onClick={() => this.sortBy(leadsData, 'work_experience', 1)}></button>
                                                                                    <button className={this.state.showValue === "RejectedWorkExpDown" ? "down active" : "down"} onClick={() => this.sortBy(leadsData, 'work_experience', -1)}></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>

                                                                        <th className="appHead-heading">More Info</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <>
                                                                        {leadsData?.length > 0 ?

                                                                            leadsData.map((item, index) => (
                                                                                <tr className="shown trhover cursor-point" onClick={() => this.openLeadApplication(item)}>
                                                                                    <td className="appIDnum text-center">{(page * limit) + (index + 1)}</td>
                                                                                    <td>
                                                                                        <div className="d-flex flex-column" data-toggle="modal"
                                                                                            data-target="#myModa20" >
                                                                                            <b className="appIDLeads">{item.cart_application_id}</b>
                                                                                            <span className="mt-1 date appIDDates">{item.created_at ? moment(item.created_at.split('T')[0]).format('ll') : "-"}</span>
                                                                                        </div></td>
                                                                                    <td><p className="d-block appIDCourseName appTextline">{item.product_name}</p></td>
                                                                                    <td className="appIDProfile">{item.profession}</td>
                                                                                    <td className="appIDProfile">{item.dob ? moment(item.dob).format('DD/MM/YYYY') : "-"}</td>
                                                                                    {activeWizrTab === 'WIZR Skills' && (<td className="appIDProfile">{item.gender}</td>)}
                                                                                    {activeWizrTab === 'WIZR Skills' && (<td className="appIDProfile">{item?.qualification ? item.qualification : "-"}</td>)}
                                                                                    <td className="appIDProfile">{item.work_experience ? item.work_experience : "-"}</td>


                                                                                    <td><i className="fa fa-angle-right float-right pt-2  cursor-point" style={{ fontSize: "1.464vw" }} data-toggle="modal" data-target="#myModaxl8"   ></i></td>


                                                                                </tr>


                                                                            ))


                                                                            :
                                                                            <td colSpan="10"><NoRecordsFound message={globalseraching? searchedKeyword :'No Data to show '} show_searched={globalseraching} /></td>
                                                                        }

                                                                    </>
                                                                </tbody>
                                                            </table>
                                                            <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-4">

                                                                {/* <div className="d-flex align-items-center row_per_page mr-lg-5 mr-4">
                                                                <p>Row per page </p>
                                                                <select page={this.state.page} onChange={this.handleChangelimitOfPage}>
                                                                    <option value={10}>10</option>
                                                                    <option value={20}>20</option>
                                                                    <option value={30}>30</option>
                                                                </select>
                                                            </div>  */}

                                                                <div className="d-flex align-items-center pages">
                                                                    {/* <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} /> */}
                                                                    {count > 10 && <TablePagination
                                                                        component="div"
                                                                        count={count}
                                                                        page={this.state.page}
                                                                        rowsPerPage={this.state.limit}
                                                                        onPageChange={this.handleChangePageNew}
                                                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                                                        //   className="leadtablepagination"
                                                                        // labelDisplayedRows={({ page }) => {
                                                                        //   return `Page: ${this.state.page} of 200 `;
                                                                        // }}
                                                                        // labelDisplayedRows={<span>Rows:</span>}
                                                                        labelDisplayedRows={({ page }) => {

                                                                            return `Page: ${this.state.page + 1} of ${totalPages} `;

                                                                        }}
                                                                    />
                                                                    }                                                            </div>

                                                            </div>
                                                        </div>
                                                        {/* <div style={{ display: 'block', padding: 30, marginLeft: "76%" }}>
                                                            <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} />
                                                        </div> */}

                                                    </div>


                                                    {/* end */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Filter />

                <CenteredPopUp show={this.state.showApprovedCenteredPopUp} closePopUp={this.closeApprovedcenterPopUp} getWidthclass="w60" >
                    <div className='LeadApprovedModal'>
                        <h1 className='LeadApprovedModalTitle mrb'>Approve Lead*</h1>
                        <div><p className="LeadApprovedModalSubTitle ">Please select the details before approving<br /> the lead</p></div>
                        {/* <div class="dropdown">
                  {
                        <button type="button" class="btn d-flex justify-content-between w-100 align-items-center btn-primary dropdown-toggle" data-toggle="dropdown">
                            {this.state.selectedqueryType}
                        </button>  */}


                        <label className="ApproveLeadlabel mb-2" for="QueryType">Batch Start Date</label>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StyledDatePicker
                                open={this.state.isBatchStartdate}
                                inputVariant="outlined"
                                label=""
                                views={['year', 'month', 'day']}
                                value={this.state.isBatchStartdate && this.state.BatchStartDate !== "" ? this.state.BatchStartDate : undefined}
                                onChange={(value) => this.handleStartBatchdate(value)}
                                slotProps={{ textField: { placeholder: 'Date', onClick: () => this.setState({ isBatchStartdate: !this.state.isBatchStartdate, isBatchLastdate: false }), readOnly: true } }}
                                //disablePast
                                minDate={this.state.isBatchStartdate ? dayjs().add(1, 'day') : ""}
                            />
                        </LocalizationProvider>

                        <label className="ApproveLeadlabel mb-2" for="QueryType">Last enrolment date</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}   >
                            <StyledDatePicker
                                open={this.state.isBatchLastdate}
                                inputVariant="outlined"
                                label=''
                                views={['year', 'month', 'day']}
                                slotProps={{ textField: { placeholder: 'Date', onClick: () => this.setState({ isBatchLastdate: !this.state.isBatchLastdate, isBatchStartdate: false }), readOnly: true } }}
                                onChange={(value) => this.handleLastBatchdate(value)}
                                disablePast
                                maxDate={this.state.isBatchLastdate ? dayjs(this.state.BatchStartDate).subtract(1, 'day') : ""}
                                value={this.state.BatchLastDate !== "" ? this.state.BatchLastDate : undefined}
                            />
                        </LocalizationProvider>
                        {/* <button className="ApproveSubmit" type="button" onClick={()=>this.setState({showApprovedCenteredPopUp:false})}>Submit</button>      */}
                        <div className="text-danger mt-5 LeadApprovedError text-center">{approveFailedMsg != "" ? approveFailedMsg : ""}</div>


                        <button className={`ApproveSubmit ${(BatchStartDate && BatchLastDate !== '') && !this.state.invalidStartDate && !this.state.invalidEndDate ? 'enabledButtonStyle' : 'disabledButtonStyle'}`} type="button" onClick={this.handleApproved} disabled={BatchStartDate && BatchLastDate !== '' ? false : true}>Submit</button>
                    </div>

                </CenteredPopUp>



                <CenteredPopUp show={this.state.showonholdCenteredPopUp} closePopUp={this.closeonholdcenterPopUp} getWidthclass="w432" >
                    <div className='LeadApprovedModal'>
                        <h1 className='LeadApprovedModalTitle'>On hold Leads*</h1>
                        <p className="LeadApprovedModalSubTitle">Please enter the additional details required for approving the lead</p>
                        {/* <div class="dropdown">
                  {
                        <button type="button" class="btn d-flex justify-content-between w-100 align-items-center btn-primary dropdown-toggle" data-toggle="dropdown">
                            {this.state.selectedqueryType}
                        </button>  */}
                        <label className="ApproveLeadlabel mb-2" style={{ marginTop: '44px' }} for="QueryType">Ask for more details</label>
                        <StyledTextArea
                            aria-label="minimum height"
                            minRows={5}
                            sx={{ width: '100%' }}
                            onChange={(e) => this.setState({ reasonOnhold: e.target.value })}
                        />
                        {/* <button className="ApproveSubmit" type="button" onClick={()=>this.setState({showonholdCenteredPopUp:false})}>Submit</button>      */}
                        <div className="text-danger mt-5 LeadApprovedError text-center">{approveFailedMsg != "" ? approveFailedMsg : ""}</div>
                        <button className={`ApproveSubmit ${reasonOnhold != "" && reasonOnhold.length > 1 ? 'enabledButtonStyle' : 'disabledButtonStyle'}`} type="button" onClick={this.handleOnHold} disabled={reasonOnhold !== '' && reasonOnhold.length > 1 ? false : true}>Submit</button>
                    </div>

                </CenteredPopUp>






                <CenteredPopUp show={this.state.showrejectedCenteredPopUp} closePopUp={this.closerejectedcenterPopUp} getWidthclass="w432" >
                    <div className='LeadApprovedModal'>
                        <h1 className='LeadApprovedModalTitle'>Reject Leads*</h1>
                        <p className="LeadApprovedModalSubTitle">Please enter the reason for rejecting the lead</p>
                        {/* <div class="dropdown">
                    {
                            <button type="button" class="btn d-flex justify-content-between w-100 align-items-center btn-primary dropdown-toggle" data-toggle="dropdown">
                                {this.state.selectedqueryType}
                            </button>  */}
                        <label className="ApproveLeadlabel mb-2" style={{ marginTop: '44px' }} for="QueryType">Reason</label>
                        <StyledTextArea
                            aria-label="minimum height"
                            minRows={5}
                            sx={{ width: '100%' }}
                            onChange={(e) => this.setState({ rejectedReason: e.target.value })}
                        />
                        {/* <button className="ApproveSubmit" type="button" onClick={()=>this.setState({showrejectedCenteredPopUp:false})}>Submit</button>  'disabledButtonStyle    */}
                        <div className="text-danger mt-5 LeadApprovedError text-center">{approveFailedMsg != "" ? approveFailedMsg : ""}</div>
                        <button className={`ApproveSubmit ${rejectedReason !== "" && rejectedReason.length > 1 ? 'enabledButtonStyle' : 'disabledButtonStyle'}`} type="button" onClick={this.handleRejectLeads} disabled={rejectedReason !== '' && rejectedReason.length > 1 ? false : true}>Submit</button>
                    </div>

                </CenteredPopUp>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { leads, leadsCount, show_searched, globalSearch, activeWizrTab, globalseraching, leadSkills, searchedKeyword, approveFailedMsg, merchant_journey } = state.user;
    const { user_id, token, sfid, salesForceToken } = state.auth;
    const { message } = state.message;
    return {
        sfid,
        leads,
        leadsCount,
        user_id,
        token,
        message,
        show_searched,
        globalSearch,
        activeWizrTab,
        globalseraching,
        leadSkills,
        searchedKeyword,
        approveFailedMsg,
        salesForceToken,
        merchant_journey
    };
}
export default connect(mapStateToProps)(Leads);